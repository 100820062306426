import React, { useState, useEffect } from "react";
import "../../CSS/JSAT8.css";
import SignatureCanvas from "react-signature-canvas";
import { SignatureCapture } from "../SignatureCapture";

export default function T8Sig(props) {
  const [visible, setVisible] = useState(false);
  /*useEffect(() => {
    if (Object.keys(props.Table).length !== 0) {
      props.setT8(props.T8, (props.T8[0] = { props.Table }));
    } else if (props.T8 !== undefined) {
      if (props.T8[0] !== undefined) {
        props.setTable(props.T8[0].props.Table);
      }
    }
  }, [props, props.Table]);*/
  return visible ? (
    <div className="body2JSA8">
      <SignatureCapture
        Keys={props.Keys}
        setTable={props.setTable}
        Table={props.Table}
        visible={visible}
        setVisible={setVisible}
        signature={props.Table[props.Keys][1]}
      />
    </div>
  ) : (
    <div className="RowJSA8" key={props.Keys}>
      <div className="test101">
        <div className="SubBtnJSA8" onClick={() => setVisible(!visible)}>
          {props.Table[props.Keys][1] ? (
            <img className="prevJSA8" src={props.Table[props.Keys][1]} alt="" />
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
}
