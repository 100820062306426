import React from "react";
import "../../CSS/TimesheetComment.css";

export default function TimesheetLineComment(props) {
  return (
    <div className="body">
      <textarea
        className="textInputTestTSComment"
        placeholder=""
        value={props.Comment}
        //onChangeText={props.setComment}
        onChange={(e) => props.setComment(e.target.value)}
        maxLength={400}
      />
    </div>
  );
}
