// Import the functions you need from the SDKs you need

import "firebase/auth";
import { getAuth } from "firebase/auth";
import "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCoCrYKP1JjzSOb2kPOHbhYOhazCmXy0nY",
  authDomain: "sfms-ce695.firebaseapp.com",
  projectId: "sfms-ce695",
  storageBucket: "sfms-ce695.appspot.com",
  messagingSenderId: "152813023965",
  appId: "1:152813023965:web:184921027a9c2af59f6134",
  measurementId: "G-7PX2GC6MRJ",
};

// Initialize Firebase
export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebaseApp.firestore();
export const FBstorage = getStorage(firebaseApp, "gs://sfms-ce695.appspot.com");
export const authentication = getAuth(firebaseApp);

export default authentication;

/*export const fetchJobs = async (company) => {
  const response = db.collection("PLEnerserv");
  await response.get();
};*/
export const fetchUsersCompany = async (email) => {
  const response = db.collection("Users");
  const snapshot = await response.get();
  const users = snapshot.docs.map((doc) => ({
    ...doc.data(),
  }));
  users.forEach((item) => {
    if (
      item.Email.toLowerCase() ===
      getAuth(firebaseApp).currentUser.email.toLowerCase()
    ) {
      window.localStorage.setItem("Company", item.Company);
      window.localStorage.setItem("BaseId", item.Bid);
      return item.Admin;
    }
  });
};
/*export const useLoadJobs = () => {
  const Lifts = ref([]);
  const close = PLECollection.orderBy("id", "asc").onSnapshot((snapshot) => {
    Lifts.value = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      baseId: doc.id,
    }));
  });

  onUnmounted(close);
  return Lifts;
};*/
export const getJobs = async (company) => {
  var Lifts = "";
  const snapshot = await firebase
    .firestore()
    .collection(window.localStorage.getItem("Company"))
    .get();
  Lifts = snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
    baseId: doc.id,
  }));
  return Lifts;
};
