import React, { useState, useEffect } from "react";
import "../../CSS/FRT1.css";

export default function FRT1(props) {
  const [Line0, setLine0] = useState({});
  const [Line1, setLine1] = useState({});
  const [Line2, setLine2] = useState({});
  const [Line3, setLine3] = useState({});
  useEffect(() => {
    if (Object.keys(Line0).length !== 0) {
      props.setT1(props.T1, (props.T1[0] = { Line0 }));
    }
    if (Object.keys(Line1).length !== 0) {
      props.setT1(props.T1, (props.T1[1] = { Line1 }));
    }
    if (Object.keys(Line2).length !== 0) {
      props.setT1(props.T1, (props.T1[2] = { Line2 }));
    }
    if (Object.keys(Line3).length !== 0) {
      props.setT1(props.T1, (props.T1[3] = { Line3 }));
    } else if (props.T1 !== undefined) {
      if (props.T1[0] !== undefined) {
        setLine0(props.T1[0].Line0);
      }
      if (props.T1[1] !== undefined) {
        setLine1(props.T1[1].Line1);
      }
      if (props.T1[2] !== undefined) {
        setLine2(props.T1[2].Line2);
      }
      if (props.T1[3] !== undefined) {
        setLine3(props.T1[3].Line3);
      }
    }
  }, [props, Line0, Line1, Line2, Line3]);
  return (
    <div className="bodyF1">
      <div className="ColumnTitleF1">Progress</div>

      <div className="subBodyF6">
        <div className="ColumnF1">
          <div className="TitleF1">
            <div className="TitleText1F1">ACTIVITY</div>
            <div className="TitleText1F1">
              (EX: GRADE, EXCAVATING, SEEDING, ETC.)
            </div>
          </div>
          <div className="RowF1">
            <input
              className="textInputTestF1"
              placeholder=""
              value={Line0.Activity}
              onChange={(event) => {
                setLine0({ ...Line0, Activity: event.target.value });
              }}
            />
          </div>
          <div className="RowF1">
            <input
              className="textInputTestF1"
              placeholder=""
              value={Line1.Activity}
              onChange={(event) => {
                setLine1({ ...Line1, Activity: event.target.value });
              }}
            />
          </div>
          <div className="RowF1">
            <input
              className="textInputTestF1"
              placeholder=""
              value={Line2.Activity}
              onChange={(event) => {
                setLine2({ ...Line2, Activity: event.target.value });
              }}
            />
          </div>
          <div className="RowF1">
            <input
              className="textInputTestF1"
              placeholder=""
              value={Line3.Activity}
              onChange={(event) => {
                setLine3({ ...Line3, Activity: event.target.value });
              }}
            />
          </div>
        </div>
        <div className="Column2F1">
          <div className="TitleF1">
            <div className="TitleText1F1">BEGIN</div>
            <div className="TitleText1F1">STATION</div>
          </div>
          <div className="RowF1">
            <input
              className="textInputTestF1"
              placeholder=""
              value={Line0.BeginStation}
              onChange={(event) => {
                setLine0({ ...Line0, BeginStation: event.target.value });
              }}
            />
          </div>
          <div className="RowF1">
            <input
              className="textInputTestF1"
              placeholder=""
              value={Line1.BeginStation}
              onChange={(event) => {
                setLine1({ ...Line1, BeginStation: event.target.value });
              }}
            />
          </div>
          <div className="RowF1">
            <input
              className="textInputTestF1"
              placeholder=""
              value={Line2.BeginStation}
              onChange={(event) => {
                setLine2({ ...Line2, BeginStation: event.target.value });
              }}
            />
          </div>
          <div className="RowF1">
            <input
              className="textInputTestF1"
              placeholder=""
              value={Line3.BeginStation}
              onChange={(event) => {
                setLine3({ ...Line3, BeginStation: event.target.value });
              }}
            />
          </div>
        </div>

        <div className="Column2F1">
          <div className="TitleF1">
            <div className="TitleText1F1">END</div>
            <div className="TitleText1F1">STATION</div>
          </div>
          <div className="RowF1">
            <input
              className="textInputTestF1"
              placeholder=""
              value={Line0.EndStation}
              onChange={(event) => {
                setLine0({ ...Line0, EndStation: event.target.value });
              }}
            />
          </div>
          <div className="RowF1">
            <input
              className="textInputTestF1"
              placeholder=""
              value={Line1.EndStation}
              onChange={(event) => {
                setLine1({ ...Line1, EndStation: event.target.value });
              }}
            />
          </div>
          <div className="RowF1">
            <input
              className="textInputTestF1"
              placeholder=""
              value={Line2.EndStation}
              onChange={(event) => {
                setLine2({ ...Line2, EndStation: event.target.value });
              }}
            />
          </div>
          <div className="RowF1">
            <input
              className="textInputTestF1"
              placeholder=""
              value={Line3.EndStation}
              onChange={(event) => {
                setLine3({ ...Line3, EndStation: event.target.value });
              }}
            />
          </div>
        </div>

        <div className="Column2F1">
          <div className="TitleF1">
            <div className="TitleText1F1">FOOTAGE</div>
            <div className="TitleText1F1"> / AREA</div>
          </div>
          <div className="RowF1">
            <input
              className="textInputTestF1"
              placeholder=""
              value={Line0.FOOTAGE}
              onChange={(event) => {
                setLine0({ ...Line0, FOOTAGE: event.target.value });
              }}
            />
          </div>
          <div className="RowF1">
            <input
              className="textInputTestF1"
              placeholder=""
              value={Line1.FOOTAGE}
              onChange={(event) => {
                setLine1({ ...Line1, FOOTAGE: event.target.value });
              }}
            />
          </div>
          <div className="RowF1">
            <input
              className="textInputTestF1"
              placeholder=""
              value={Line2.FOOTAGE}
              onChange={(event) => {
                setLine2({ ...Line2, FOOTAGE: event.target.value });
              }}
            />
          </div>
          <div className="RowF1">
            <input
              className="textInputTestF1"
              placeholder=""
              value={Line3.FOOTAGE}
              onChange={(event) => {
                setLine3({ ...Line3, FOOTAGE: event.target.value });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
