import React, { useState, useEffect } from "react";
import "../../CSS/JSAT8.css";
import SignatureCanvas from "react-signature-canvas";
import { SignatureCapture } from "../SignatureCapture";
import T8Sig from "./T8Sig";
export default function JSAT8(props) {
  const [Table, setTable] = useState({});
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (Object.keys(Table).length !== 0) {
      props.setT8(props.T8, (props.T8[0] = { Table }));
    } else if (props.T8 !== undefined) {
      if (props.T8[0] !== undefined) {
        setTable(props.T8[0].Table);
      }
    }
  }, [props, Table]);
  const addRow = () => {
    var rows = ["", ""];
    var temp = Object.keys(Table).length;
    var Keys = "Rows" + temp;
    setTable({
      ...Table,
      [Keys]: rows,
    });
    //rows.push({ name: "", sig: "", num: temp });
    //setRows([...rows]);
  };
  return (
    <div className="body2JSA8">
      <div className="bodyJSA8">
        <div className="ColumnJSA8">
          <div className="RowJSA8">
            <div className="test101">Print Name:</div>
          </div>
          {Object.keys(Table)
            .sort()
            .map((Keys, r) => (
              <div className="RowJSA8" key={Keys}>
                <input
                  className="textInputTestSig"
                  placeholder=""
                  value={Table[Keys][0]}
                  onChange={(event) => {
                    var temp = Keys;
                    var temp2;
                    //[temp] = [event];
                    var [temp] = [event.target.value, "dsfsdf"];
                    temp2 = [temp];
                    if (Table[Keys][1] !== undefined) {
                      temp2[1] = Table[Keys][1];
                    } else {
                      temp2[1] = "";
                    }
                    setTable({
                      ...Table,
                      [Keys]: temp2,
                    });
                  }}
                />
              </div>
            ))}
        </div>
        {
          <div className="ColumnJSA8">
            <div className="RowJSA8">
              <div className="test101">Signature:</div>
            </div>
            {Object.keys(Table)
              .sort()
              .map((Keys, r) => (
                <T8Sig Table={Table} setTable={setTable} Keys={Keys} />
              ))}
          </div>
        }
      </div>
      <button className="AddBtnJSA8" title="Add Row" onClick={() => addRow()}>
        <div className="LockText">Add Row</div>
      </button>
    </div>
  );
}
