import React, { useState } from "react";
import { db } from "../FirebaseLink";
import { Link } from "react-router-dom";
import "../CSS/allForeman.css";

export default function AllForeman(props) {
  const Delete = async (temp) => {
    if (
      window.confirm("Are you sure you wish to delete this Foreman Report?")
    ) {
      await db.collection(props.jobNum).doc(temp.baseId).delete();
    }
    /*
    Alert.alert(
      "Delete Report?",
      "Are you sure you wish to delete this Foreman Report?",
      [
        {
          text: "Delete",
          style: "destructive",
          onClick: async () => {
            await db.collection(props.jobNum).doc(temp.baseId).delete();
          },
        },
        {
          text: "Cancel",
          style: "cancel",
          // If the user confirmed, then we dispatch the action we blocked earlier
          // This will continue the action that had triggered the removal of the screen
          onClick: async () => {},
        },
      ]
    );
    */
  };
  if (props.job !== undefined) {
    return (
      <div className="Cont" key={1}>
        <div className="globalFiles" key={2}>
          {props.job.map((file, i) => {
            file.JobNum = props.jobNum;
            file.user = props.user;
            if (file.Type === "Foreman Report") {
              if (file.TypeExtra !== "Template") {
                if (
                  file.Header[0].Line0.Date !== undefined &&
                  file.Header[0].Line0.Date !== null &&
                  file.Header[0].Line0.Date !== "" &&
                  file.hasBeenUpdated === "yes"
                ) {
                  if (
                    (
                      file.Header[0].Line0.Date.split(" ")[1] +
                      " " +
                      file.Header[0].Line0.Date.split(" ")[2] +
                      " " +
                      file.Header[0].Line0.Date.split(" ")[3]
                    )
                      .toLowerCase()
                      .includes(props.search.toLowerCase())
                  ) {
                    return (
                      <div className="GCTS" key={i + ".00"}>
                        <div className="containerTS" key={i + ".01"}>
                          <div className="existingJobTS" key={i + ".02"}>
                            <Link
                              className="existingJobBtnTS"
                              to={
                                "/" +
                                file.JobNum +
                                "/ForemanReport/" +
                                file.baseId
                              }
                              key={i + ".03"}
                              color="white"
                            >
                              <div className="existingJobBtnTS">
                                {file.Header[0].Line0.Date.split(" ")[1] + " "}
                                {file.Header[0].Line0.Date.split(" ")[2] + " "}
                                {file.Header[0].Line0.Date.split(" ")[3]}
                              </div>
                            </Link>
                          </div>
                          {props.visible ? (
                            <div className="existingJob2TS" key={i + ".04"}>
                              <button
                                className="existingJobBtn3TS"
                                onClick={() => Delete(file)}
                                title={"X"}
                                color="white"
                                key={i + ".05"}
                              >
                                -
                              </button>
                            </div>
                          ) : (
                            <div key={i + ".06"}></div>
                          )}
                        </div>
                      </div>
                    );
                  } else {
                    return <div key={i + ".07"}></div>;
                  }
                } else {
                  return (
                    <div className="GCTS" key={i + ".08"}>
                      <div className="containerTS" key={i + ".09"}>
                        <div className="existingJobTS" key={i + ".10"}>
                          <Link
                            className="existingJobBtnTS"
                            to={
                              "/" +
                              file.JobNum +
                              "/ForemanReport/" +
                              file.baseId
                            }
                            key={i + ".11"}
                            color="white"
                          >
                            <div className="existingJobBtnTS" key={i + ".12"}>
                              {file.hasBeenUpdated === "dup"
                                ? "Duplicate"
                                : "New Report"}
                            </div>
                          </Link>
                        </div>
                        {props.visible ? (
                          <div className="existingJob2TS" key={i + ".13"}>
                            <button
                              className="existingJobBtn3TS"
                              onClick={() => Delete(file)}
                              title={"X"}
                              color="white"
                              key={i + ".14"}
                            >
                              -
                            </button>
                          </div>
                        ) : (
                          <div key={i + ".15"}></div>
                        )}
                      </div>
                    </div>
                  );
                }
              } else {
                return <div key={i + ".16"}></div>;
              }
            } else {
              return <div key={i + ".16"}></div>;
            }
          })}
        </div>
      </div>
    );
  } else {
    return null;
  }
}
