import React, { useState, useEffect } from "react";
import "../../CSS/JSAT4.css";
import { ImCheckmark } from "react-icons/im";
export default function JSAT4(props) {
  const [Table, setTable] = useState({});
  useEffect(() => {
    if (Object.keys(Table).length !== 0) {
      props.setT4(props.T4, (props.T4[0] = { Table }));
    } else if (props.T4 !== undefined) {
      if (props.T4[0] !== undefined) {
        setTable(props.T4[0].Table);
      }
    }
  }, [props, Table]);
  return (
    <div className="body">
      <div className="ColumnTitle">
        <div className="Title">
          <div className="TitleText1">PPE Required:</div>
        </div>
      </div>
      <div className="Column">
        <div className="Row">
          <div className="TitleText1">Safety Glasses</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (
                Table.SafetyGlasses !== null ||
                Table.SafetyGlasses !== undefined
              ) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  SafetyGlasses: !Table.SafetyGlasses,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  SafetyGlasses: true,
                });
              }
            }}
          >
            {Table.SafetyGlasses ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>
        <div className="Row">
          <div className="TitleText1">Hard Hat</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (Table.HardHat !== null || Table.HardHat !== undefined) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  HardHat: !Table.HardHat,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  HardHat: true,
                });
              }
            }}
          >
            {Table.HardHat ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>

        <div className="Row">
          <div className="TitleText1">Safety Vest</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (Table.SafetyVest !== null || Table.SafetyVest !== undefined) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  SafetyVest: !Table.SafetyVest,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  SafetyVest: true,
                });
              }
            }}
          >
            {Table.SafetyVest ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>

        <div className="Row">
          <div className="TitleText1">Safety-Toe Boots</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (
                Table.SafetyToeBoots !== null ||
                Table.SafetyToeBoots !== undefined
              ) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  SafetyToeBoots: !Table.SafetyToeBoots,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  SafetyToeBoots: true,
                });
              }
            }}
          >
            {Table.SafetyToeBoots ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>

        <div className="Row">
          <div className="TitleText1">Gloves</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (Table.Gloves !== null || Table.Gloves !== undefined) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Gloves: !Table.Gloves,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Gloves: true,
                });
              }
            }}
          >
            {Table.Gloves ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>
      </div>

      <div className="Column">
        <div className="Row">
          <div className="TitleText1">FR Clothing</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (Table.FRClothing !== null || Table.FRClothing !== undefined) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  FRClothing: !Table.FRClothing,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  FRClothing: true,
                });
              }
            }}
          >
            {Table.FRClothing ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>
        <div className="Row">
          <div className="TitleText1">Fall Protection</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (
                Table.FallProtection !== null ||
                Table.FallProtection !== undefined
              ) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  FallProtection: !Table.FallProtection,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  FallProtection: true,
                });
              }
            }}
          >
            {Table.FallProtection ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>

        <div className="Row">
          <div className="TitleText1">Winter Footwear</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (
                Table.WinterFootwear !== null ||
                Table.WinterFootwear !== undefined
              ) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  WinterFootwear: !Table.WinterFootwear,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  WinterFootwear: true,
                });
              }
            }}
          >
            {Table.WinterFootwear ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>
        <div className="Row">
          <div className="TitleText1">Hearing Protection</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (
                Table.HearingProtection !== null ||
                Table.HearingProtection !== undefined
              ) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  HearingProtection: !Table.HearingProtection,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  HearingProtection: true,
                });
              }
            }}
          >
            {Table.HearingProtection ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>

        <div className="Row">
          <div className="TitleText1">Resporatory Protection</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (
                Table.ResporatoryProtection !== null ||
                Table.ResporatoryProtection !== undefined
              ) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  ResporatoryProtection: !Table.ResporatoryProtection,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  ResporatoryProtection: true,
                });
              }
            }}
          >
            {Table.ResporatoryProtection ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>
      </div>

      <div className="Column">
        <div className="Row">
          <div className="TitleText1">Chain Saw Chaps</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (
                Table.ChainsawChaps !== null ||
                Table.ChainsawChaps !== undefined
              ) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  ChainsawChaps: !Table.ChainsawChaps,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  ChainsawChaps: true,
                });
              }
            }}
          >
            {Table.ChainsawChaps ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>

        <div className="Row">
          <div className="TitleText1">Metatarsal Guards</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (
                Table.MetalarsalGuards !== null ||
                Table.MetalarsalGuards !== undefined
              ) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  MetalarsalGuards: !Table.MetalarsalGuards,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  MetalarsalGuards: true,
                });
              }
            }}
          >
            {Table.MetalarsalGuards ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>
        <div className="Row">
          <div className="TitleText1">Life Vest</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (Table.LifeVest !== null || Table.LifeVest !== undefined) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  LifeVest: !Table.LifeVest,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  LifeVest: true,
                });
              }
            }}
          >
            {Table.LifeVest ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>
        <div className="Row">
          <div className="TitleText1">Face Shielding</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (
                Table.FaceShielding !== null ||
                Table.FaceShielding !== undefined
              ) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  FaceShielding: !Table.FaceShielding,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  FaceShielding: true,
                });
              }
            }}
          >
            {Table.FaceShielding ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>

        <div className="Row">
          <div className="TitleText1">Other</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (Table.Other !== null || Table.Other !== undefined) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Other: !Table.Other,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Other: true,
                });
              }
            }}
          >
            {Table.Other ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
