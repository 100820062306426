import React, { useState, useEffect } from "react";
import "../../CSS/JSAT11.css";

export default function JSAT11(props) {
  const [Line0, setLine0] = useState({});
  useEffect(() => {
    if (Object.keys(Line0).length !== 0) {
      props.setT11(props.T11, (props.T11[0] = { Line0 }));
    } else if (props.T11 !== undefined) {
      if (props.T11[0] !== undefined) {
        setLine0(props.T11[0].Line0);
      }
    }
  }, [props, Line0]);

  const addRow = () => {
    var rows = ["", "", ""];
    var temp = Object.keys(Line0).length;
    var Keys = "Rows" + temp;
    setLine0({
      ...Line0,
      [Keys]: rows,
    });
    //rows.push({ name: "", sig: "", num: temp });
    //setRows([...rows]);
  };

  return (
    <div>
      <div className="bodyJSA11">
        <div className="ColumnJSA11">
          <div className="Row">
            <div className="TitleText1">Task / Job Steps</div>
          </div>
          {/*} <div className="Row">
          <input
            className="textInputTest"
            placeholder=""
            value={Line0.Task}
            onChange={(event) => {
              setLine0({ ...Line0, Task: event.target.value });
            }}
          />*
          </div>*/}
          {Object.keys(Line0)
            .sort()
            .map((Keys, r) => (
              <div className="RowJSA8" key={Keys}>
                <input
                  className="textInputTestSig"
                  placeholder=""
                  value={Line0[Keys][0]}
                  onChange={(event) => {
                    var temp3 = Line0[Keys];
                    temp3[0] = event.target.value;
                    //[temp] = [event];
                    setLine0({
                      ...Line0,
                      [Keys]: temp3,
                    });
                  }}
                />
              </div>
            ))}
        </div>
        <div className="ColumnJSA11">
          <div className="Row">
            <div className="TitleText1">Potential hazards</div>
          </div>
          {/*<div className="Row">
          <input
            className="textInputTest"
            placeholder=""
            value={Line0.Hazards}
            onChange={(event) => {
              setLine0({ ...Line0, Hazards: event.target.value });
            }}
          />
        </div>*/}
          {Object.keys(Line0)
            .sort()
            .map((Keys, r) => (
              <div className="RowJSA8" key={Keys}>
                <input
                  className="textInputTestSig"
                  placeholder=""
                  value={Line0[Keys][1]}
                  onChange={(event) => {
                    var temp3 = Line0[Keys];
                    temp3[1] = event.target.value;
                    //[temp] = [event];
                    setLine0({
                      ...Line0,
                      [Keys]: temp3,
                    });
                  }}
                />
              </div>
            ))}
        </div>
        <div className="ColumnJSA11">
          <div className="Row">
            <div className="TitleText1">Safe Work Recommendations</div>
          </div>
          {/*<div className="Row">
          <input
            className="textInputTest"
            placeholder=""
            value={Line0.Recommendations}
            onChange={(event) => {
              setLine0({ ...Line0, Recommendations: event.target.value });
            }}
          />
        </div>*/}
          {Object.keys(Line0)
            .sort()
            .map((Keys, r) => (
              <div className="RowJSA8" key={Keys}>
                <input
                  className="textInputTestSig"
                  placeholder=""
                  value={Line0[Keys][2]}
                  onChange={(event) => {
                    var temp3 = Line0[Keys];
                    temp3[2] = event.target.value;
                    //[temp] = [event];
                    setLine0({
                      ...Line0,
                      [Keys]: temp3,
                    });
                  }}
                />
              </div>
            ))}
        </div>
      </div>

      <button className="AddBtnJSA11" title="Add Row" onClick={() => addRow()}>
        <div className="LockText">Add Row</div>
      </button>
    </div>
  );
}
