import React, { useState, useEffect } from "react";
import "../../CSS/FRT6.css";
export default function FRT6(props) {
  const [Line0, setLine0] = useState({});
  const [Line1, setLine1] = useState({});
  const [Line2, setLine2] = useState({});
  const [Line3, setLine3] = useState({});
  useEffect(() => {
    if (Object.keys(Line0).length !== 0) {
      props.setT6(props.T6, (props.T6[0] = { Line0 }));
    }
    if (Object.keys(Line1).length !== 0) {
      props.setT6(props.T6, (props.T6[1] = { Line1 }));
    }
    if (Object.keys(Line2).length !== 0) {
      props.setT6(props.T6, (props.T6[2] = { Line2 }));
    }
    if (Object.keys(Line3).length !== 0) {
      props.setT6(props.T6, (props.T6[3] = { Line3 }));
    } else if (props.T6 !== undefined) {
      if (props.T6[0] !== undefined) {
        setLine0(props.T6[0].Line0);
      }
      if (props.T6[1] !== undefined) {
        setLine1(props.T6[1].Line1);
      }
      if (props.T6[2] !== undefined) {
        setLine2(props.T6[2].Line2);
      }
      if (props.T6[3] !== undefined) {
        setLine3(props.T6[3].Line3);
      }
    }
  }, [props, Line0, Line1, Line2, Line3]);
  return (
    <div className="bodyF6">
      <div className="ColumnTitleF6">
        General Unit Items
        <div className="ColumnSubTitleF6">
          (Ex. Silt Fence, Hay Bales, Mats, Concrete, Site Rock, Site Fill,
          Excavation Amount, Construction Entrances, Orange Fence, Goal Post,
          Test Stations, etc.)
        </div>
      </div>
      <div className="subBodyF6">
        <div className="Column1F6">
          <div className="TitleF6">
            <div className="TitleText1F6">ITEM</div>
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line0.Item1}
              onChange={(event) => {
                setLine0({ ...Line0, Item1: event.target.value });
              }}
            />
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line1.Item1}
              onChange={(event) => {
                setLine1({ ...Line1, Item1: event.target.value });
              }}
            />
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line2.Item1}
              onChange={(event) => {
                setLine2({ ...Line2, Item1: event.target.value });
              }}
            />
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line3.Item1}
              onChange={(event) => {
                setLine3({ ...Line3, Item1: event.target.value });
              }}
            />
          </div>
        </div>
        <div className="Column2F1">
          <div className="TitleF6">
            <div className="TitleText1F6">QTY</div>
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line0.Qty1}
              onChange={(event) => {
                setLine0({ ...Line0, Qty1: event.target.value });
              }}
            />
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line1.Qty1}
              onChange={(event) => {
                setLine1({ ...Line1, Qty1: event.target.value });
              }}
            />
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line2.Qty1}
              onChange={(event) => {
                setLine2({ ...Line2, Qty1: event.target.value });
              }}
            />
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line3.Qty1}
              onChange={(event) => {
                setLine3({ ...Line3, Qty1: event.target.value });
              }}
            />
          </div>
        </div>
        <div className="Column1F6">
          <div className="TitleF6">
            <div className="TitleText1F6">ITEM</div>
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line0.Item2}
              onChange={(event) => {
                setLine0({ ...Line0, Item2: event.target.value });
              }}
            />
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line1.Item2}
              onChange={(event) => {
                setLine1({ ...Line1, Item2: event.target.value });
              }}
            />
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line2.Item2}
              onChange={(event) => {
                setLine2({ ...Line2, Item2: event.target.value });
              }}
            />
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line3.Item2}
              onChange={(event) => {
                setLine3({ ...Line3, Item2: event.target.value });
              }}
            />
          </div>
        </div>
        <div className="Column2F1">
          <div className="TitleF6">
            <div className="TitleText1F6">QTY</div>
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line0.Qty2}
              onChange={(event) => {
                setLine0({ ...Line0, Qty2: event.target.value });
              }}
            />
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line1.Qty2}
              onChange={(event) => {
                setLine1({ ...Line1, Qty2: event.target.value });
              }}
            />
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line2.Qty2}
              onChange={(event) => {
                setLine2({ ...Line2, Qty2: event.target.value });
              }}
            />
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line3.Qty2}
              onChange={(event) => {
                setLine3({ ...Line3, Qty2: event.target.value });
              }}
            />
          </div>
        </div>
        <div className="Column1F6">
          <div className="TitleF6">
            <div className="TitleText1F6">ITEM</div>
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line0.Item3}
              onChange={(event) => {
                setLine0({ ...Line0, Item3: event.target.value });
              }}
            />
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line1.Item3}
              onChange={(event) => {
                setLine1({ ...Line1, Item3: event.target.value });
              }}
            />
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line2.Item3}
              onChange={(event) => {
                setLine2({ ...Line2, Item3: event.target.value });
              }}
            />
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line3.Item3}
              onChange={(event) => {
                setLine3({ ...Line3, Item3: event.target.value });
              }}
            />
          </div>
        </div>
        <div className="Column2F1">
          <div className="TitleF6">
            <div className="TitleText1F6">QTY</div>
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line0.Qty3}
              onChange={(event) => {
                setLine0({ ...Line0, Qty3: event.target.value });
              }}
            />
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line1.Qty3}
              onChange={(event) => {
                setLine1({ ...Line1, Qty3: event.target.value });
              }}
            />
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line2.Qty3}
              onChange={(event) => {
                setLine2({ ...Line2, Qty3: event.target.value });
              }}
            />
          </div>
          <div className="RowF6">
            <input
              className="textInputTestF6"
              placeholder=""
              value={Line3.Qty3}
              onChange={(event) => {
                setLine3({ ...Line3, Qty3: event.target.value });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
