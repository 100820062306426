import React, { useState, useEffect } from "react";
import "../../CSS/JSAT6.css";
import { ImCheckmark } from "react-icons/im";
export default function JSAT6(props) {
  const [Table, setTable] = useState({});
  useEffect(() => {
    if (Object.keys(Table).length !== 0) {
      props.setT6(props.T6, (props.T6[0] = { Table }));
    } else if (props.T6 !== undefined) {
      if (props.T6[0] !== undefined) {
        setTable(props.T6[0].Table);
      }
    }
  }, [props, Table]);
  return (
    <div className="body">
      <div className="ColumnTitle">
        <div className="Title">
          <div className="TitleText1">Other Equipment:</div>
        </div>
      </div>
      <div className="Column">
        <div className="Row">
          <div className="TitleText1"></div>
        </div>
        <div className="RowBool"></div>
        <div className="Row">
          <div className="TitleText1">Fire Extinguishers</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (
                Table.FireExtinuishers !== null ||
                Table.FireExtinuishers !== undefined
              ) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  FireExtinuishers: !Table.FireExtinuishers,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  FireExtinuishers: true,
                });
              }
            }}
          >
            {Table.FireExtinuishers ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>

        <div className="Row">
          <div className="TitleText1">Gas Monitor</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (Table.GasMonitor !== null || Table.GasMonitor !== undefined) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  GasMonitor: !Table.GasMonitor,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  GasMonitor: true,
                });
              }
            }}
          >
            {Table.GasMonitor ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>

        <div className="Row">
          <div className="TitleText1">First Aid Kit</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (
                Table.FirstAidKit !== null ||
                Table.FirstAidKit !== undefined
              ) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  FirstAidKit: !Table.FirstAidKit,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  FirstAidKit: true,
                });
              }
            }}
          >
            {Table.FirstAidKit ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>

        <div className="Row">
          <div className="TitleText1">Lighting</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (Table.Lighting !== null || Table.Lighting !== undefined) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Lighting: !Table.Lighting,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Lighting: true,
                });
              }
            }}
          >
            {Table.Lighting ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>
      </div>

      <div className="Column">
        <div className="Row">
          <div className="TitleText1">Safety Data Sheets</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (
                Table.SaftyDataSheets !== null ||
                Table.SaftyDataSheets !== undefined
              ) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  SaftyDataSheets: !Table.SaftyDataSheets,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  SaftyDataSheets: true,
                });
              }
            }}
          >
            {Table.SaftyDataSheets ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>
        <div className="Row">
          <div className="TitleText1">Stop Paddles</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (
                Table.StopPaddles !== null ||
                Table.StopPaddles !== undefined
              ) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  StopPaddles: !Table.StopPaddles,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  StopPaddles: true,
                });
              }
            }}
          >
            {Table.StopPaddles ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>

        <div className="Row">
          <div className="TitleText1">Road Signs</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (Table.RoadSigns !== null || Table.RoadSigns !== undefined) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  RoadSigns: !Table.RoadSigns,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  RoadSigns: true,
                });
              }
            }}
          >
            {Table.RoadSigns ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>
        <div className="Row">
          <div className="TitleText1">Air Horn</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (Table.AirHorn !== null || Table.AirHorn !== undefined) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  AirHorn: !Table.AirHorn,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  AirHorn: true,
                });
              }
            }}
          >
            {Table.AirHorn ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>

        <div className="Row">
          <div className="TitleText1">One Calls</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (Table.OneCalls !== null || Table.OneCalls !== undefined) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  OneCalls: !Table.OneCalls,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  OneCalls: true,
                });
              }
            }}
          >
            {Table.OneCalls ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>
      </div>

      <div className="Column">
        <div className="Row">
          <div className="TitleText1">Slings/Chains</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (Table.Slings !== null || Table.Slings !== undefined) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Slings: !Table.Slings,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Slings: true,
                });
              }
            }}
          >
            {Table.Slings ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>
        <div className="Row">
          <div className="TitleText1">Two-way Radios</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (
                Table.Two_Way_Radio !== null ||
                Table.Two_Way_Radio !== undefined
              ) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Two_Way_Radio: !Table.Two_Way_Radio,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Two_Way_Radio: true,
                });
              }
            }}
          >
            {Table.Two_Way_Radio ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>

        <div className="Row">
          <div className="TitleText1">Other</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (Table.Other !== null || Table.Other !== undefined) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Other: !Table.Other,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Other: true,
                });
              }
            }}
          >
            {Table.Other ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>
        <div className="Row"></div>
        <div className="RowBool"></div>
        <div className="Row"></div>
        <div className="RowBool"></div>
      </div>
    </div>
  );
}
