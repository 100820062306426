import React, { useState, useEffect } from "react";
import "../../CSS/FRT2.css";
import { ImCheckmark } from "react-icons/im";

export default function FRT2(props) {
  const [Line0, setLine0] = useState({});
  const [Line1, setLine1] = useState({});
  const [Line2, setLine2] = useState({});
  useEffect(() => {
    if (Object.keys(Line0).length !== 0) {
      props.setT2(props.T2, (props.T2[0] = { Line0 }));
    }
    if (Object.keys(Line1).length !== 0) {
      props.setT2(props.T2, (props.T2[1] = { Line1 }));
    }
    if (Object.keys(Line2).length !== 0) {
      props.setT2(props.T2, (props.T2[2] = { Line2 }));
    } else if (props.T2 !== undefined) {
      if (props.T2[0] !== undefined) {
        setLine0(props.T2[0].Line0);
      }
      if (props.T2[1] !== undefined) {
        setLine1(props.T2[1].Line1);
      }
      if (props.T2[2] !== undefined) {
        setLine2(props.T2[2].Line2);
      }
    }
  }, [props, Line0, Line1, Line2]);
  return (
    <div className="bodyF2">
      <div className="ColumnTitleF2">
        <div className="TitleF2">Coating</div>
      </div>

      <div className="subBodyF6">
        <div className="ColumnF2">
          <div className="TitleLeftRowF2"></div>
          <div className="RowsF2">
            <div className="RowF2">
              {/*<input
                className="textInputTestF2"
                placeholder=""
                value={Line0.Type1}
                onChange={(event) => {
                  setLine0({ ...Line0, Type1: event.target.value });
                }}
              />*/}

              <div
                className="SubBtnFR2"
                title="Lock"
                onClick={() => {
                  if (Line0.Type1 !== null || Line0.Type1 !== undefined) {
                    //[temp] = [event];
                    setLine0({
                      ...Line0,
                      Type1: !Line0.Type1,
                    });
                  } else {
                    //[temp] = [event];
                    setLine0({ ...Line0, Type1: true });
                  }
                }}
              >
                {Line0.Type1 ? (
                  <div className="true">
                    <div className="trueTextFR">
                      <ImCheckmark className="checkmark" />
                    </div>
                  </div>
                ) : (
                  <div className="false"></div>
                )}
              </div>
            </div>
            <div className="RowF2">
              {/*<input
                className="textInputTestF2"
                placeholder=""
                value={Line1.Type1}
                onChange={(event) => {
                  setLine1({ ...Line1, Type1: event.target.value });
                }}
              />*/}
              <div
                className="SubBtnFR2"
                title="Lock"
                onClick={() => {
                  if (Line1.Type1 !== null || Line1.Type1 !== undefined) {
                    //[temp] = [event];
                    setLine1({
                      ...Line1,
                      Type1: !Line1.Type1,
                    });
                  } else {
                    //[temp] = [event];
                    setLine1({ ...Line1, Type1: true });
                  }
                }}
              >
                {Line1.Type1 ? (
                  <div className="true">
                    <div className="trueTextFR">
                      <ImCheckmark className="checkmark" />
                    </div>
                  </div>
                ) : (
                  <div className="false"></div>
                )}
              </div>
            </div>
            <div className="RowF2">
              {/*<input
                className="textInputTestF2"
                placeholder=""
                value={Line2.Type1}
                onChange={(event) => {
                  setLine2({ ...Line2, Type1: event.target.value });
                }}
              />*/}
              <div
                className="SubBtnFR2"
                title="Lock"
                onClick={() => {
                  if (Line2.Type1 !== null || Line2.Type1 !== undefined) {
                    //[temp] = [event];
                    setLine2({
                      ...Line2,
                      Type1: !Line2.Type1,
                    });
                  } else {
                    //[temp] = [event];
                    setLine2({ ...Line2, Type1: true });
                  }
                }}
              >
                {Line2.Type1 ? (
                  <div className="true">
                    <div className="trueTextFR">
                      <ImCheckmark className="checkmark" />
                    </div>
                  </div>
                ) : (
                  <div className="false"></div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="ColumnF2-2">
          <div className="TitleRowF2">
            <div className="TitleText2F2"> </div>
          </div>
          <div className="RowsF2">
            <div className="RowTitleF2">Mainline</div>
            <div className="RowTitleF2">Mainline</div>
            <div className="RowTitleF2">Mainline</div>
          </div>
        </div>
        <div className="ColumnF2">
          <div className="TitleRowF2">
            <div className="TitleText2F2"></div>
          </div>
          <div className="RowsF2">
            <div className="RowF2">
              {/*<input
                className="textInputTestF2"
                placeholder=""
                value={Line0.Type2}
                onChange={(event) => {
                  setLine0({ ...Line0, Type2: event.target.value });
                }}
              />*/}
              <div
                className="SubBtnFR2"
                title="Lock"
                onClick={() => {
                  if (Line0.Type2 !== null || Line0.Type2 !== undefined) {
                    //[temp] = [event];
                    setLine0({
                      ...Line0,
                      Type2: !Line0.Type2,
                    });
                  } else {
                    //[temp] = [event];
                    setLine0({ ...Line0, Type2: true });
                  }
                }}
              >
                {Line0.Type2 ? (
                  <div className="true">
                    <div className="trueTextFR">
                      <ImCheckmark className="checkmark" />
                    </div>
                  </div>
                ) : (
                  <div className="false"></div>
                )}
              </div>
            </div>
            <div className="RowF2">
              {/*} <input
                className="textInputTestF2"
                placeholder=""
                value={Line1.Type2}
                onChange={(event) => {
                  setLine1({ ...Line1, Type2: event.target.value });
                }}
              />*/}
              <div
                className="SubBtnFR2"
                title="Lock"
                onClick={() => {
                  if (Line1.Type2 !== null || Line1.Type2 !== undefined) {
                    //[temp] = [event];
                    setLine1({
                      ...Line1,
                      Type2: !Line1.Type2,
                    });
                  } else {
                    //[temp] = [event];
                    setLine1({ ...Line1, Type2: true });
                  }
                }}
              >
                {Line1.Type2 ? (
                  <div className="true">
                    <div className="trueTextFR">
                      <ImCheckmark className="checkmark" />
                    </div>
                  </div>
                ) : (
                  <div className="false"></div>
                )}
              </div>
            </div>
            <div className="RowF2">
              {/*} <input
                className="textInputTestF2"
                placeholder=""
                value={Line2.Type2}
                onChange={(event) => {
                  setLine2({ ...Line2, Type2: event.target.value });
                }}
              />*/}
              <div
                className="SubBtnFR2"
                title="Lock"
                onClick={() => {
                  if (Line2.Type2 !== null || Line2.Type2 !== undefined) {
                    //[temp] = [event];
                    setLine2({
                      ...Line2,
                      Type2: !Line2.Type2,
                    });
                  } else {
                    //[temp] = [event];
                    setLine2({ ...Line2, Type2: true });
                  }
                }}
              >
                {Line2.Type2 ? (
                  <div className="true">
                    <div className="trueTextFR">
                      <ImCheckmark className="checkmark" />
                    </div>
                  </div>
                ) : (
                  <div className="false"></div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="ColumnF2-2">
          <div className="TitleRowF2">
            <div className="TitleText4F2">TY</div>
          </div>
          <div className="RowsF2">
            <div className="RowTitleF2">HDD/Bore</div>
            <div className="RowTitleF2">HDD/Bore</div>
            <div className="RowTitleF2">HDD/Bore</div>
          </div>
        </div>
        <div className="ColumnF2">
          <div className="TitleRowF2">
            <div className="TitleText3F2">PE</div>
          </div>
          <div className="RowsF2">
            <div className="RowF2">
              {/*<input
                className="textInputTestF2"
                placeholder=""
                value={Line0.Type3}
                onChange={(event) => {
                  setLine0({ ...Line0, Type3: event.target.value });
                }}
              />*/}
              <div
                className="SubBtnFR2"
                title="Lock"
                onClick={() => {
                  if (Line0.Type3 !== null || Line0.Type3 !== undefined) {
                    //[temp] = [event];
                    setLine0({
                      ...Line0,
                      Type3: !Line0.Type3,
                    });
                  } else {
                    //[temp] = [event];
                    setLine0({ ...Line0, Type3: true });
                  }
                }}
              >
                {Line0.Type3 ? (
                  <div className="true">
                    <div className="trueTextFR">
                      <ImCheckmark className="checkmark" />
                    </div>
                  </div>
                ) : (
                  <div className="false"></div>
                )}
              </div>
            </div>
            <div className="RowF2">
              {/*<input
                className="textInputTestF2"
                placeholder=""
                value={Line1.Type3}
                onChange={(event) => {
                  setLine1({ ...Line1, Type3: event.target.value });
                }}
              />*/}
              <div
                className="SubBtnFR2"
                title="Lock"
                onClick={() => {
                  if (Line1.Type3 !== null || Line1.Type3 !== undefined) {
                    //[temp] = [event];
                    setLine1({
                      ...Line1,
                      Type3: !Line1.Type3,
                    });
                  } else {
                    //[temp] = [event];
                    setLine1({ ...Line1, Type3: true });
                  }
                }}
              >
                {Line1.Type3 ? (
                  <div className="true">
                    <div className="trueTextFR">
                      <ImCheckmark className="checkmark" />
                    </div>
                  </div>
                ) : (
                  <div className="false"></div>
                )}
              </div>
            </div>
            <div className="RowF2">
              {/*<input
                className="textInputTestF2"
                placeholder=""
                value={Line2.Type3}
                onChange={(event) => {
                  setLine2({ ...Line2, Type3: event.target.value });
                }}
              />*/}
              <div
                className="SubBtnFR2"
                title="Lock"
                onClick={() => {
                  if (Line2.Type3 !== null || Line2.Type3 !== undefined) {
                    //[temp] = [event];
                    setLine2({
                      ...Line2,
                      Type3: !Line2.Type3,
                    });
                  } else {
                    //[temp] = [event];
                    setLine2({ ...Line2, Type3: true });
                  }
                }}
              >
                {Line2.Type3 ? (
                  <div className="true">
                    <div className="trueTextFR">
                      <ImCheckmark className="checkmark" />
                    </div>
                  </div>
                ) : (
                  <div className="false"></div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="ColumnF2-2">
          <div className="TitleRowF2">
            <div className="TitleText2F2"> </div>
          </div>
          <div className="RowsF2">
            <div className="RowTitleF2">Fabrication</div>
            <div className="RowTitleF2">Fabrication</div>
            <div className="RowTitleF2">Fabrication</div>
          </div>
        </div>
        <div className="ColumnF2">
          <div className="TitleRowF2"></div>
          <div className="RowsF2">
            <div className="RowF2">
              {/*} <input
                className="textInputTestF2"
                placeholder=""
                value={Line0.Type4}
                onChange={(event) => {
                  setLine0({ ...Line0, Type4: event.target.value });
                }}
              />*/}
              <div
                className="SubBtnFR2"
                title="Lock"
                onClick={() => {
                  if (Line0.Type4 !== null || Line0.Type4 !== undefined) {
                    //[temp] = [event];
                    setLine0({
                      ...Line0,
                      Type4: !Line0.Type4,
                    });
                  } else {
                    //[temp] = [event];
                    setLine0({ ...Line0, Type4: true });
                  }
                }}
              >
                {Line0.Type4 ? (
                  <div className="true">
                    <div className="trueTextFR">
                      <ImCheckmark className="checkmark" />
                    </div>
                  </div>
                ) : (
                  <div className="false"></div>
                )}
              </div>
            </div>
            <div className="RowF2">
              {/*} <input
                className="textInputTestF2"
                placeholder=""
                value={Line1.Type4}
                onChange={(event) => {
                  setLine1({ ...Line1, Type4: event.target.value });
                }}
              />*/}
              <div
                className="SubBtnFR2"
                title="Lock"
                onClick={() => {
                  if (Line1.Type4 !== null || Line1.Type4 !== undefined) {
                    //[temp] = [event];
                    setLine1({
                      ...Line1,
                      Type4: !Line1.Type4,
                    });
                  } else {
                    //[temp] = [event];
                    setLine1({ ...Line1, Type4: true });
                  }
                }}
              >
                {Line1.Type4 ? (
                  <div className="true">
                    <div className="trueTextFR">
                      <ImCheckmark className="checkmark" />
                    </div>
                  </div>
                ) : (
                  <div className="false"></div>
                )}
              </div>
            </div>
            <div className="RowF2">
              {/*<input
                className="textInputTestF2"
                placeholder=""
                value={Line2.Type4}
                onChange={(event) => {
                  setLine2({ ...Line2, Type4: event.target.value });
                }}
              />*/}
              <div
                className="SubBtnFR2"
                title="Lock"
                onClick={() => {
                  if (Line2.Type4 !== null || Line2.Type4 !== undefined) {
                    //[temp] = [event];
                    setLine2({
                      ...Line2,
                      Type4: !Line2.Type4,
                    });
                  } else {
                    //[temp] = [event];
                    setLine2({ ...Line2, Type4: true });
                  }
                }}
              >
                {Line2.Type4 ? (
                  <div className="true">
                    <div className="trueTextFR">
                      <ImCheckmark className="checkmark" />
                    </div>
                  </div>
                ) : (
                  <div className="false"></div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="ColumnF2-2">
          <div className="TitleRight2RowF2">
            <div className="TitleText2F2"> </div>
          </div>
          <div className="RowsF2">
            <div className="RowTitleF2">Fitting</div>
            <div className="RowTitleF2">Fitting</div>
            <div className="RowTitleF2">Fitting</div>
          </div>
        </div>
        <div className="ColumnF2">
          <div className="TitleRight2RowF2">
            <div className="TitleText2F2">QUANTITY</div>
          </div>
          <div className="RowsF2">
            <div className="RowF2">
              <input
                className="textInputTestF2"
                placeholder=""
                value={Line0.Quantity}
                onChange={(event) => {
                  setLine0({ ...Line0, Quantity: event.target.value });
                }}
              />
            </div>
            <div className="RowF2">
              <input
                className="textInputTestF2"
                placeholder=""
                value={Line1.Quantity}
                onChange={(event) => {
                  setLine1({ ...Line1, Quantity: event.target.value });
                }}
              />
            </div>
            <div className="RowF2">
              <input
                className="textInputTestF2"
                placeholder=""
                value={Line2.Quantity}
                onChange={(event) => {
                  setLine2({ ...Line2, Quantity: event.target.value });
                }}
              />
            </div>
          </div>
        </div>
        <div className="ColumnF2">
          <div className="TitleRight2RowF2">
            <div className="TitleText2F2">DIAMETER</div>
          </div>
          <div className="RowsF2">
            <div className="RowF2v2">
              <input
                className="textInputTestF2"
                placeholder=""
                value={Line0.Diameter}
                onChange={(event) => {
                  setLine0({ ...Line0, Diameter: event.target.value });
                }}
              />
            </div>
            <div className="RowF2v2">
              <input
                className="textInputTestF2"
                placeholder=""
                value={Line1.Diameter}
                onChange={(event) => {
                  setLine1({ ...Line1, Diameter: event.target.value });
                }}
              />
            </div>
            <div className="RowF2v2">
              <input
                className="textInputTestF2"
                placeholder=""
                value={Line2.Diameter}
                onChange={(event) => {
                  setLine2({ ...Line2, Diameter: event.target.value });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
