import { db } from "../../FirebaseLink";
import React, { useState, useEffect } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import "../../CSS/OQ.css";
import Loading from "../../Loading";
import { Document, Page, pdfjs, PDFDownloadLink } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
//import "../../CSS/OQ.css";

//import * as FileSystem from "expo-file-system";
//import * as Sharing from "expo-sharing";

export default function OQ(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [JobNum] = useState(
    window.location.href.split("/")[3].replace("%20", " ")
  );
  const [fileId] = useState(window.location.href.split("/")[5]);
  const [PageNum, setPageNum] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [file, setFile] = useState({});
  const [onLoad, setOnLoad] = useState(true);
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      if (onLoad === true) {
        (async () => {
          setIsLoading(true);
          const docRef = doc(db, JobNum, fileId);
          const docSnap = await getDoc(docRef);
          setOnLoad(false);
          setFile(docSnap.data());
          setIsLoading(false);
        })();
      }
    }
    return () => {
      // cancel the subscription
      isSubscribed = false;
    };
  }, [file]);
  const incPage = () => {
    // Update state with incremented value
    if (PageNum + 1 <= maxPages) {
      setPageNum(PageNum + 1);
    }
  };

  const decPage = () => {
    // Update state with incremented value
    if (PageNum - 1 !== 0) {
      setPageNum(PageNum - 1);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setMaxPages(numPages);
  };
  const MyDoc = () => (
    <Document file={file.URI}>
      <Page></Page>
    </Document>
  );
  /*const downlaod=()=>{
  <a href='/somefile.txt' download>Click to download</a>
}*/

  return (
    <div className="GC">
      {isLoading ? <Loading /> : <div></div>}
      <div className="prev">
        <div className="OQDocFlex">
          <div className="OQDoc">
            <Document file={file.URI} onLoadSuccess={onDocumentLoadSuccess}>
              <Page pageNumber={PageNum} />
            </Document>
          </div>
        </div>
        <div className="OQClicker">
          <button onClick={incPage} className="OQClickerUp">
            <div className="OQClickerDownloadText">+</div>
          </button>
          <div className="OQClickerCount">{PageNum + " / " + maxPages}</div>
          <button onClick={decPage} className="OQClickerDown">
            <div className="OQClickerDownloadText">-</div>
          </button>
        </div>
      </div>
      <div className="OQClickerDownload">
        <a href={file.URI} className="OQClickerDownloadText">
          Download
        </a>
      </div>
    </div>
  );
}
