import { db } from "./FirebaseLink";
import React, { useState, useEffect } from "react";
import { orderBy, query, onSnapshot, collection } from "firebase/firestore";
import AllTimesheet from "./all_Folder/allTimesheet";
import AllForeman from "./all_Folder/allForeman";
import AllJSA from "./all_Folder/allJSA";
import AllOQ from "./all_Folder/allOQ";
import NewForemanReport from "./NewForemanReport";
import NewJSA from "./NewJSA";
import NewTimesheet from "./NewTimesheet";
import NewOQ from "./NewOQ";
import Duplicate from "./all_Folder/allTimesheetDup";
import AllForemanDup from "./all_Folder/AllForemanDup";
import AllJSADup from "./all_Folder/allJSADup";
import EditBtn from "./EditBtn";
import Search from "./Search";
import "./CSS/Job.css";
import Loading from "./Loading";
import { AiOutlineMenu } from "react-icons/ai";

export default function Job(props) {
  const [searchPhrase, setSearchPhrase] = useState("");
  const [clicked, setClicked] = useState(false);
  const [contentT, setContentTimesheet] = useState(false);
  const [contentJ, setContentJSA] = useState(false);
  const [contentO, setContentOQ] = useState(false);
  const [contentF, setContentFR] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisableTime, setisVisableTime] = useState(false);
  const [isVisableJSA, setisVisableJSA] = useState(false);
  const [isVisableFR, setisVisableFR] = useState(false);

  const componentHideAndShowTimesheet = () => {
    if (contentF) {
      setContentFR(false);
    }
    if (contentO) {
      setContentOQ(false);
    }
    if (contentJ) {
      setContentJSA(false);
    }
    setContentTimesheet(!contentT);
  };
  const componentHideAndShowJSA = () => {
    if (contentF) {
      setContentFR(false);
    }
    if (contentO) {
      setContentOQ(false);
    }
    if (contentT) {
      setContentTimesheet(false);
    }
    setContentJSA(!contentJ);
  };
  const componentHideAndShowOQ = () => {
    if (contentF) {
      setContentFR(false);
    }
    if (contentJ) {
      setContentJSA(false);
    }
    if (contentT) {
      setContentTimesheet(false);
    }
    setContentOQ(!contentO);
  };
  const componentHideAndShowFR = () => {
    if (contentO) {
      setContentOQ(false);
    }
    if (contentJ) {
      setContentJSA(false);
    }
    if (contentT) {
      setContentTimesheet(false);
    }
    setContentFR(!contentF);
  };
  const [Job, setJobs] = useState([]);
  const [JobNum] = useState(
    window.location.href.split("/")[3].replace("%20", " ")
  );

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, JobNum), orderBy("id", "desc")),
      (snapshot) => {
        setJobs(snapshot.docs.map((doc) => doc.data()));
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="GC">
      {isLoading ? <Loading /> : <div></div>}
      <Search
        searchPhrase={searchPhrase}
        setSearchPhrase={setSearchPhrase}
        clicked={clicked}
        setClicked={setClicked}
      />
      <div className="allJobFiles">
        <div className="snap">
          <div className="existingJobJob">
            {" "}
            <AiOutlineMenu
              className={contentT ? "menuIconActive" : "menuIcon"}
            />
            <button
              title="Timesheets"
              onClick={componentHideAndShowTimesheet}
              className="existingJobBtn"
            >
              Timesheets
            </button>
          </div>
          {contentT ? (
            <div className="allTimesheetContent">
              <AllTimesheet
                job={Job}
                jobNum={JobNum}
                search={searchPhrase}
                visible={isVisableTime}
                setVisible={setisVisableTime}
              />
              <div className="leftCont">
                <div className="leftContCont">
                  <div className="leftContSticky">
                    <NewTimesheet job={Job} jobNum={JobNum} tempKey={1} />
                    <Duplicate job={Job} jobNum={JobNum} />
                    <EditBtn
                      visible={isVisableTime}
                      setVisible={setisVisableTime}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="snap">
          <div className="existingJobJob">
            {" "}
            <AiOutlineMenu
              className={contentJ ? "menuIconActive" : "menuIcon"}
            />
            <button
              title="JSA"
              onClick={componentHideAndShowJSA}
              className="existingJobBtn"
            >
              JSAs
            </button>
          </div>
          {contentJ ? (
            <div className="allTimesheetContent">
              <AllJSA
                job={Job}
                jobNum={JobNum}
                search={searchPhrase}
                visible={isVisableJSA}
                setVisible={setisVisableJSA}
              />
              <div className="leftCont">
                <div className="leftContCont">
                  <div className="leftContSticky">
                    <NewJSA job={Job} jobNum={JobNum} tempKey={2} />
                    <AllJSADup job={Job} jobNum={JobNum} />
                    <EditBtn
                      visible={isVisableJSA}
                      setVisible={setisVisableJSA}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="snap">
          <div className="existingJobJob">
            {" "}
            <AiOutlineMenu
              className={contentF ? "menuIconActive" : "menuIcon"}
            />
            <button
              title="Foreman Reports"
              onClick={componentHideAndShowFR}
              className="existingJobBtn"
            >
              Foreman Reports
            </button>
          </div>
          {contentF ? (
            <div className="allTimesheetContent">
              <AllForeman
                job={Job}
                jobNum={JobNum}
                search={searchPhrase}
                visible={isVisableFR}
                setVisible={setisVisableFR}
              />
              <div className="leftCont">
                <div className="leftContCont">
                  <div className="leftContSticky">
                    <NewForemanReport job={Job} jobNum={JobNum} tempKey={3} />
                    <AllForemanDup job={Job} jobNum={JobNum} />
                    <EditBtn
                      visible={isVisableFR}
                      setVisible={setisVisableFR}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="snap">
          <div className="existingJobJob">
            {" "}
            <AiOutlineMenu
              className={contentO ? "menuIconActive" : "menuIcon"}
            />
            <button
              title="OQ"
              onClick={componentHideAndShowOQ}
              className="existingJobBtn"
            >
              OQs
            </button>
          </div>
          {contentO ? (
            <div>
              <AllOQ job={Job} jobNum={JobNum} />
              <NewOQ job={Job} jobNum={JobNum} tempKey={1} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
