//import { useNavigation } from "@react-navigation/core";
import React, { useEffect, useState } from "react";
import "./CSS/login.css";
import { useNavigate } from "react-router-dom";

import { signInWithEmailAndPassword } from "firebase/auth";
import authentication from "./FirebaseLink";
import Logo from "../LoginLogo.png";
import { fetchUsersCompany } from "./FirebaseLink";

const LoginScreen = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  useEffect(() => {}, []);
  const handleLogin = async () => {
    signInWithEmailAndPassword(authentication, email, password)
      .then(async (userCredentials) => {
        props.setAuthenticated(true);
        window.localStorage.setItem("isLoggedIn", true);
        navigate("/", {
          state: { temp: true },
        });
      })
      .catch((userCredentials) => console.log(userCredentials));
  };

  return (
    <div className="containerL" behavior="padding">
      <img src={Logo} className="Logo" alt=""></img>
      <div className="inputContainerL">
        <div className="inputContainerMain">
          <input
            className="inputL"
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <input
            className="inputL"
            placeholder="Password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="buttonContainer">
        <button onClick={handleLogin} className="buttonL">
          <div className="buttonTextL">Login</div>
        </button>
      </div>
    </div>
  );
};

export default LoginScreen;
