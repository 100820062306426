import React from "react";
import "./CSS/Search.css";
import { AiOutlineSearch } from "react-icons/ai";

export default function Search(props) {
  return (
    <div className="containerSearch">
      <div className="searchBar__clicked">
        {/* Input field */}
        <input
          className="inputSearch"
          placeholder="Search"
          value={props.searchPhrase}
          onChange={(e) => props.setSearchPhrase(e.target.value)}
          onFocus={() => {
            props.setClicked(true);
          }}
        ></input>
      </div>
      {/* cancel button, depending on whether the search bar is clicked or not */}
    </div>
  );
}
