import { db } from "./FirebaseLink";
import React from "react";
import "./CSS/NewTimesheet.css";
import Loading from "./Loading";
export default class NewTimesheet extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
    };
  }
  render() {
    const DoBoth = async () => {
      await NewTimesheet();
    };
    const NewTimesheet = async () => {
      const ref = db.collection(this.props.jobNum).doc();
      await db
        .collection(this.props.jobNum)
        .doc(ref._delegate._key.path.segments[1])
        .set({
          Type: "Timesheet",
          TypeExtra: "null",
          baseId: ref._delegate._key.path.segments[1],
          TimesheetLines: { Line0: ["", "", "", "", "", "", ""] },
          TimesheetHeader: {},
          id: this.props.job.length,
          hasBeenUpdated: "no",
        });
      /*const ehehe = await response.add({
        Type: "Timesheet",
        baseId: ref._delegate._key.path.segments[1],
      });*/
    };
    return (
      <div className="containerBar" key={1}>
        {this.state.isLoading ? <Loading /> : <div></div>}
        <div className="newJob" key={1}>
          <button
            key={1}
            className="NewJobBtn"
            onClick={() => DoBoth() /*props.navigation.navigate("Timesheet")*/}
            title="New Timesheet"
            color="white"
          >
            New Timesheet
          </button>
        </div>
      </div>
    );
  }
}
