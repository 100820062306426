import { db } from "./FirebaseLink";
import React from "react";
import Loading from "./Loading";

import "./CSS/NewForemanReport.css";
export default class NewForemanReport extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
    };
  }
  render() {
    const DoBoth = async () => {
      await NewFR();
    };
    const NewFR = async () => {
      this.setState({
        isLoading: true,
      });
      const ref = db.collection(this.props.jobNum).doc();
      await db
        .collection(this.props.jobNum)
        .doc(ref._delegate._key.path.segments[1])
        .set({
          Type: "Foreman Report",
          TypeExtra: "null",
          baseId: ref._delegate._key.path.segments[1],
          Header: [{ Line0: {} }],
          T1: [{ Line0: {} }, { Line1: {} }],
          T2: [{ Line0: {} }],
          T3: [{ Line0: {} }],
          T4: [{ Line0: {} }],
          T5: [{ Line0: {} }],
          T6: [{ Line0: {} }, { Line1: {} }],
          T7: [{ Line0: {} }],
          id: this.props.job.length,
          hasBeenUpdated: "no",
        });
      /*const ehehe = await response.add({
        Type: "Timesheet",
        baseId: ref._delegate._key.path.segments[1],
      });*/
      this.setState({
        isLoading: false,
      });
    };
    return (
      <div className="containerBar" key={1}>
        {this.state.isLoading ? <Loading /> : <div></div>}
        <div className="newJob" key={1}>
          <button
            key={1}
            className="NewJobBtn"
            onClick={() => DoBoth() /*props.navigation.navigate("Timesheet")*/}
            title="New Foreman Report"
            color="white"
          >
            New Foreman Report
          </button>
        </div>
      </div>
    );
  }
}
