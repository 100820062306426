import React, { useState, useEffect } from "react";
import "../../CSS/FRT5.css";
export default function FRT5(props) {
  const [Line0, setLine0] = useState({});
  const [Line1, setLine1] = useState({});
  const [Line2, setLine2] = useState({});
  const [Line3, setLine3] = useState({});
  const [Line4, setLine4] = useState({});
  const [Line5, setLine5] = useState({});
  useEffect(() => {
    if (Object.keys(Line0).length !== 0) {
      props.setT5(props.T5, (props.T5[0] = { Line0 }));
    }
    if (Object.keys(Line1).length !== 0) {
      props.setT5(props.T5, (props.T5[1] = { Line1 }));
    }
    if (Object.keys(Line2).length !== 0) {
      props.setT5(props.T5, (props.T5[2] = { Line2 }));
    }
    if (Object.keys(Line3).length !== 0) {
      props.setT5(props.T5, (props.T5[3] = { Line3 }));
    }
    if (Object.keys(Line4).length !== 0) {
      props.setT5(props.T5, (props.T5[4] = { Line4 }));
    }
    if (Object.keys(Line5).length !== 0) {
      props.setT5(props.T5, (props.T5[5] = { Line5 }));
    } else if (props.T5 !== undefined) {
      if (props.T5[0] !== undefined) {
        setLine0(props.T5[0].Line0);
      }
      if (props.T5[1] !== undefined) {
        setLine1(props.T5[1].Line1);
      }
      if (props.T5[2] !== undefined) {
        setLine2(props.T5[2].Line2);
      }
      if (props.T5[3] !== undefined) {
        setLine3(props.T5[3].Line3);
      }
      if (props.T5[4] !== undefined) {
        setLine4(props.T5[4].Line4);
      }
      if (props.T5[5] !== undefined) {
        setLine5(props.T5[5].Line5);
      }
    }
  }, [props, Line0, Line1, Line2, Line3, Line4, Line5]);
  return (
    <div className="bodyF5">
      <div className="ColumnTitleF5">
        <div className="TitleF5">
          <div className="TitleText1F5">SUBCONTRACTORS UTILIZED</div>
        </div>
      </div>
      <div className="ColumnF5">
        <div className="TitleRowF5">
          <div className="TitleText1F5">HOT TAP/STOPPLE</div>
        </div>
        <div className="RowF5">
          <input
            className="textInputTestF5"
            placeholder=""
            value={Line0.ContractorsUsed}
            onChange={(event) => {
              setLine0({ ...Line0, ContractorsUsed: event.target.value });
            }}
          />
        </div>
      </div>
      <div className="ColumnF5">
        <div className="TitleRowF5">
          <div className="TitleText1F5">HDD CREW</div>
        </div>
        <div className="RowF5">
          <input
            className="textInputTestF5"
            placeholder=""
            value={Line1.ContractorsUsed}
            onChange={(event) => {
              setLine1({ ...Line1, ContractorsUsed: event.target.value });
            }}
          />
        </div>
      </div>
      <div className="ColumnF5">
        <div className="TitleRowF5">
          <div className="TitleText1F5">HELICAL PIERS</div>
        </div>
        <div className="RowF5">
          <input
            className="textInputTestF5"
            placeholder=""
            value={Line2.ContractorsUsed}
            onChange={(event) => {
              setLine2({ ...Line2, ContractorsUsed: event.target.value });
            }}
          />
        </div>
      </div>
      <div className="ColumnF5">
        <div className="TitleRowF5">
          <div className="TitleText1F5">ELECTRICAL</div>
        </div>
        <div className="RowF5">
          <input
            className="textInputTestF5"
            placeholder=""
            value={Line3.ContractorsUsed}
            onChange={(event) => {
              setLine3({ ...Line3, ContractorsUsed: event.target.value });
            }}
          />
        </div>
      </div>
      <div className="ColumnF5">
        <div className="TitleRowF5">
          <div className="TitleText1F5">PAINTING</div>
        </div>
        <div className="RowF5">
          <input
            className="textInputTestF5"
            placeholder=""
            value={Line4.ContractorsUsed}
            onChange={(event) => {
              setLine4({ ...Line4, ContractorsUsed: event.target.value });
            }}
          />
        </div>
      </div>
      <div className="ColumnF5">
        <div className="TitleRowF5">
          <div className="TitleText1F5">HYDRO-EXCAVATION</div>
        </div>
        <div className="RowF5">
          <input
            className="textInputTestF5"
            placeholder=""
            value={Line5.ContractorsUsed}
            onChange={(event) => {
              setLine5({ ...Line5, ContractorsUsed: event.target.value });
            }}
          />
        </div>
      </div>
    </div>
  );
}
