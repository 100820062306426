import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
/*import PrivacyPage from "./Pages/Privacy"; */
import Home from "./Pages/Home.jsx";
import Job from "./Pages/Job.jsx";
import Timesheet from "./Pages/FileTypes/TimesheetFolder/Timesheet";
import JSA from "./Pages/FileTypes/JSAFolder/JSA";
import FR from "./Pages/FileTypes/ForemanReportFolder/ForemanReport";
import OQ from "./Pages/FileTypes/OQFolder/OQ";
import LoginScreen from "./Pages/login";
import Terms from "./Pages/Terms";
import { useState, useEffect } from "react";
import Navbar from "./Pages/Navbar";
import Footer from "./Pages/Footer";
import PrivacyPage from "./Pages/Privacy";
import TSPDF from "./Pages/FileTypes/TimesheetFolder/ConvertToPDF";
import JSAPDF from "./Pages/FileTypes/JSAFolder/ConvertToPDFJSA";
import FRPDF from "./Pages/FileTypes/ForemanReportFolder/CovertToPDFFR";
//import OQ from "./Pages/FileTypes/OQFolder/OQ";
/*function RequireAuth(nextState, replace, next) {
  if (!authenticated) {
    replace({
      pathname: "/login",
      state: { nextPathname: nextState.location.pathname },
    });
  }
  next();
}*/
export default function App() {
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(false);
  useEffect(() => {
    if (
      window.localStorage.getItem("isLoggedIn") !== true &&
      window.localStorage.getItem("isLoggedIn") !== "true"
    ) {
      if (!authenticated) {
        navigate("/Login");
      }
    } else {
      navigate("");
    }
  }, []);

  return (
    <div className="All">
      <Navbar
        authenticated={authenticated}
        setAuthenticated={setAuthenticated}
      />
      <Routes>
        <Route path="/privacy.html" onEnter={() => window.location.reload()} />
        <Route
          path="/Login"
          element={
            <LoginScreen
              authenticated={authenticated}
              setAuthenticated={setAuthenticated}
            />
          }
        />
        <Route path="/terms" element={<Terms />} />
        <Route path="/PrivacyPage" element={<PrivacyPage />} />
        <Route path="" element={<Home />} />
        <Route path="/:id" element={<Job />} />
        <Route path="/:id/Timesheet/:id" element={<TSPDF />} />
        <Route path="/:id/JSA/:id" element={<JSAPDF />} />
        <Route path="/:id/OQ/:id" element={<OQ />} />
        <Route path="/:id/ForemanReport/:id" element={<FRPDF />} />
      </Routes>

      <Footer
        authenticated={authenticated}
        setAuthenticated={setAuthenticated}
      />
    </div>
  );
}
