import React, { useState, useEffect } from "react";
import "../../CSS/FRT3.css";
export default function FRT3(props) {
  const [Line0, setLine0] = useState({});
  const [Line1, setLine1] = useState({});
  const [Line2, setLine2] = useState({});
  const [Line3, setLine3] = useState({});
  const [Line4, setLine4] = useState({});
  useEffect(() => {
    if (Object.keys(Line0).length !== 0) {
      props.setT3(props.T3, (props.T3[0] = { Line0 }));
    }
    if (Object.keys(Line1).length !== 0) {
      props.setT3(props.T3, (props.T3[1] = { Line1 }));
    }
    if (Object.keys(Line2).length !== 0) {
      props.setT3(props.T3, (props.T3[2] = { Line2 }));
    }
    if (Object.keys(Line3).length !== 0) {
      props.setT3(props.T3, (props.T3[3] = { Line3 }));
    }
    if (Object.keys(Line4).length !== 0) {
      props.setT3(props.T3, (props.T3[4] = { Line4 }));
    } else if (props.T3 !== undefined) {
      if (props.T3[0] !== undefined) {
        setLine0(props.T3[0].Line0);
      }
      if (props.T3[1] !== undefined) {
        setLine1(props.T3[1].Line1);
      }
      if (props.T3[2] !== undefined) {
        setLine2(props.T3[2].Line2);
      }
      if (props.T3[3] !== undefined) {
        setLine3(props.T3[3].Line3);
      }
      if (props.T3[4] !== undefined) {
        setLine4(props.T3[4].Line4);
      }
    }
  }, [props, Line0, Line1, Line2, Line3, Line4]);
  return (
    <div className="bodyF3">
      <div className="ColumnTitleF3">
        <div className="TitleF3">
          <div className="TitleText1F3">FABRICATION WELDS TODAY</div>
        </div>
      </div>
      <div className="ColumnF3">
        <div className="RowF3">
          <div className="TitleText2">Diameter</div>
        </div>
        <div className="RowF3">
          <div className="TitleText2">Quantity</div>
        </div>
      </div>
      <div className="ColumnF3">
        <div className="RowF3">
          <input
            className="textInputTestF3"
            placeholder=""
            value={Line0.Diameter}
            onChange={(event) => {
              setLine0({ ...Line0, Diameter: event.target.value });
            }}
          />
        </div>
        <div className="RowF3">
          <input
            className="textInputTestF3"
            placeholder=""
            value={Line0.Quantity}
            onChange={(event) => {
              setLine0({ ...Line0, Quantity: event.target.value });
            }}
          />
        </div>
      </div>
      <div className="ColumnF3">
        <div className="RowF3">
          <input
            className="textInputTestF3"
            placeholder=""
            value={Line1.Diameter}
            onChange={(event) => {
              setLine1({ ...Line1, Diameter: event.target.value });
            }}
          />
        </div>
        <div className="RowF3">
          <input
            className="textInputTestF3"
            placeholder=""
            value={Line1.Quantity}
            onChange={(event) => {
              setLine1({ ...Line1, Quantity: event.target.value });
            }}
          />
        </div>
      </div>
      <div className="ColumnF3">
        <div className="RowF3">
          <input
            className="textInputTestF3"
            placeholder=""
            value={Line2.Diameter}
            onChange={(event) => {
              setLine2({ ...Line2, Diameter: event.target.value });
            }}
          />
        </div>
        <div className="RowF3">
          <input
            className="textInputTestF3"
            placeholder=""
            value={Line2.Quantity}
            onChange={(event) => {
              setLine2({ ...Line2, Quantity: event.target.value });
            }}
          />
        </div>
      </div>
      <div className="ColumnF3">
        <div className="RowF3">
          <input
            className="textInputTestF3"
            placeholder=""
            value={Line3.Diameter}
            onChange={(event) => {
              setLine3({ ...Line3, Diameter: event.target.value });
            }}
          />
        </div>
        <div className="RowF3">
          <input
            className="textInputTestF3"
            placeholder=""
            value={Line3.Quantity}
            onChange={(event) => {
              setLine3({ ...Line3, Quantity: event.target.value });
            }}
          />
        </div>
      </div>
      <div className="ColumnF3">
        <div className="RowF3">
          <input
            className="textInputTestF3"
            placeholder=""
            value={Line4.Diameter}
            onChange={(event) => {
              setLine4({ ...Line4, Diameter: event.target.value });
            }}
          />
        </div>
        <div className="RowF3">
          <input
            className="textInputTestF3"
            placeholder=""
            value={Line4.Quantity}
            onChange={(event) => {
              setLine4({ ...Line4, Quantity: event.target.value });
            }}
          />
        </div>
      </div>
    </div>
  );
}
