import { db } from "../../FirebaseLink";
import React from "react";
import "../../CSS/Duplicate.css";
import Loading from "../../Loading";

export default class DupJSA extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
    };
  }
  render() {
    const DoBoth = async () => {
      await NewJSA();
    };
    const NewJSA = async () => {
      this.setState({
        isLoading: true,
      });
      const ref = db.collection(this.props.jobNum).doc();
      await db
        .collection(this.props.jobNum)
        .doc(ref._delegate._key.path.segments[1])
        .set({
          T1: this.props.file.T1,
          T2: this.props.file.T2,
          T3: this.props.file.T3,
          T4: this.props.file.T4,
          T5: this.props.file.T5,
          T6: this.props.file.T6,
          T7: this.props.file.T7,
          T8: this.props.file.T8,
          T9: this.props.file.T9,
          T10: this.props.file.T10,
          T11: this.props.file.T11,
          Type: this.props.file.Type,
          signature: this.props.file.signature,
          TypeExtra: "null",
          baseId: ref._delegate._key.path.segments[1],
          id: this.props.job.length,
          hasBeenUpdated: "dup",
        });
      this.setState({
        isLoading: false,
      });
    };
    return (
      <div className="containerBar" key={1}>
        {this.state.isLoading ? <Loading /> : <div></div>}
        <div className="newJob" key={1}>
          <button
            className="existingJobBtnDup"
            onClick={() => DoBoth()}
            title="Duplicate Template"
            color="white"
          >
            Duplicate Template
          </button>
        </div>
      </div>
    );
  }
}
