import React, { useState, useEffect } from "react";
import "../../CSS/JSAT3.css";
export default function JSAT3(props) {
  const [Table, setTable] = useState({});
  useEffect(() => {
    if (Object.keys(Table).length !== 0) {
      props.setT3(props.T3, (props.T3[0] = { Table }));
    } else if (props.T3 !== undefined) {
      if (props.T3[0] !== undefined) {
        setTable(props.T3[0].Table);
      }
    }
  }, [props, Table]);
  return (
    <div className="body">
      <div className="Column">
        <div className="Row">
          <div className="TitleText2">Topics discussed for tailgate:</div>
        </div>
      </div>
      <div className="Column">
        <div className="Row">
          <textarea
            className="textInputTest"
            placeholder=""
            value={Table.Topics}
            onChange={(event) => {
              setTable({ ...Table, Topics: event.target.value });
            }}
          />
        </div>
      </div>
    </div>
  );
}
