import React, { useState } from "react";
import { Link } from "react-router-dom";
import authentication from "./FirebaseLink";
import { useNavigate } from "react-router-dom";
import "./CSS/Nav.css";
import { useEffect } from "react";
import { AiOutlineMenu, AiFillHome } from "react-icons/ai";
import { MdPrivacyTip, MdLogout, MdLogin } from "react-icons/md";

export default function Navbar(props) {
  const [visibleNav, setVisibleNav] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      window.localStorage.getItem("isLoggedIn") !== true &&
      window.localStorage.getItem("isLoggedIn") !== "true"
    ) {
      setVisibleNav(false);
    } else {
      setVisibleNav(true);
      props.setAuthenticated(true);
      navigate("/");
    }
  }, [visibleNav, props.authenticated]);
  const handleLogOut = async () => {
    authentication
      .signOut()
      .then((userCredentials) => {
        window.localStorage.setItem("isLoggedIn", false);
        window.localStorage.setItem("Company", "");
        navigate("/Login");
        props.setAuthenticated(false);
        //window.location.reload();
      })
      .catch((userCredentials) => console.log(userCredentials));
  };
  return (
    <nav className="ContainerNavbar">
      <AiOutlineMenu className="navIconMain" />
      <ul className="NavBtnFlex">
        {/*<li className="BtnLogo">
          <img src={Logo} className="LogoNav" alt="" />
  </li>*/}
        {visibleNav ? (
          <li className="BtnMain">
            <Link to={""} className="Btn">
              <AiFillHome className="navIconLeft" />
              <div className="BtnText">Home {visibleNav}</div>
            </Link>
          </li>
        ) : (
          <div></div>
        )}
        <li className="BtnSecond">
          <Link to={"Privacy.html"} onClick={"refreshPage()"} className="Btn">
            <MdPrivacyTip className="navIconLeft" />
            <div className="BtnText">Privacy</div>
          </Link>
        </li>
        {visibleNav ? (
          <li className="BtnSecond">
            <div className="Btn" onClick={handleLogOut}>
              <MdLogout className="navIconLeft" />
              <div className="BtnText">Log Out</div>
            </div>
          </li>
        ) : (
          <li className="BtnSecond">
            <div className="Btn" onClick={handleLogOut}>
              <MdLogin className="navIconLeft" />
              <div className="BtnText">Log In</div>
            </div>
          </li>
        )}
      </ul>
    </nav>
  );
}
