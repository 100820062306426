import React from "react";
import Duplicate from "../FileTypes/Dups/Duplicate";
import "../CSS/allTimesheetDup.css";
import { Link } from "react-router-dom";

export default function AllTimesheetDup(props) {
  if (props.job !== undefined) {
    return props.job.map((file, i) => {
      file.JobNum = props.jobNum;
      if (file.Type === "Timesheet") {
        if (file.TypeExtra === "Template") {
          return (
            <div key={i + ".00"} className="GCTSD">
              <div className="containerTSD" key={i + ".01"}>
                <div className="existingJobTSD" key={i + ".02"}>
                  <Link
                    className="existingJobBtnTSD"
                    to={"/" + file.JobNum + "/Timesheet/" + file.baseId}
                    key={i + ".03"}
                    color="white"
                  >
                    <div className="existingJobBtnTSD" key={i + ".04"}>
                      {file.TypeExtra}
                    </div>
                  </Link>
                </div>
              </div>
              <div key={i + ".05"} className="containerBar">
                <Duplicate
                  jobNum={file.JobNum}
                  temp
                  key={i + ".06"}
                  file={file}
                  job={props.job}
                />
              </div>
            </div>
          );
        } else {
          return <div key={i + ".07"}></div>;
        }
      } else {
        return <div key={i + ".08"}></div>;
      }
    });
  } else {
    return null;
  }
}
