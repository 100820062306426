import { db, authentication } from "../../FirebaseLink";
import React, { useState } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import "../../CSS/ForemanFooter.css";
import Loading from "../../Loading";

export default function ForemanFooter(props) {
  const [isLoading, setIsLoading] = useState(false);
  const createTimesheet = (Timesheet) => {
    setIsLoading(true);
    //Job.push(Timesheet);
    const docRef = doc(db, props.jobNum, props.fileId);
    //const reference = ref(db, "TestJob101");
    getDoc(docRef);
    if (props.ForemanSignature === null) {
      window.alert("Foreman Signature Required");
      setIsLoading(false);
    } else if (
      props.Header[0].Line0.Date === undefined ||
      props.Header[0].Line0.Date === null ||
      props.Header[0].Line0.Date === ""
    ) {
      window.alert("Date Required");
      setIsLoading(false);
    } else {
      setDoc(docRef, {
        T1: props.T1,
        T2: props.T2,
        T3: props.T3,
        T4: props.T4,
        T5: props.T5,
        T6: props.T6,
        T7: props.T7,
        Header: props.Header,
        Type: "Foreman Report",
        baseId: props.fileId,
        ForemanSignature: props.ForemanSignature,
        ClientSignature: props.ClientSignature,
        TypeExtra: props.TypeExtra,
        id: props.id,
        lastUpdatedBy: authentication.currentUser.email,
        hasBeenUpdated: "yes",
      })
        .then(() => {
          setIsLoading(false);
          window.alert("Success");
        })
        .catch((error) => {
          setIsLoading(false);
          window.alert("Submit Failed");
        });
    }
  };
  const toggleOverlay = () => {
    props.setVisible(!props.visible);
  };
  const toggleOverlay2 = () => {
    props.setVisible2(!props.visible2);
  };
  return (
    <div className="footerPage">
      {isLoading ? <Loading /> : <div></div>}
      <div className="footerPageSig">
        <div className="sigDiv">
          <button
            title="Signature"
            className="SubBtn"
            onClick={() => toggleOverlay2()}
          >
            <div className="loginText">Client Rep Signature</div>
          </button>

          <img alt="" className="prevFF" src={props.ClientSignature} />
        </div>

        <div className="sigDiv">
          <button
            title="Signature"
            className="SubBtn"
            onClick={() => toggleOverlay()}
          >
            <div className="loginText">Foreman Signature</div>
          </button>
          <img alt="" className="prevFF" src={props.ForemanSignature} />
        </div>
      </div>
      <button
        className="SubBtn"
        title="Submit"
        onClick={() => {
          createTimesheet({});
        }}
      >
        <div className="loginText">Submit</div>
      </button>
    </div>
  );
}
