import { db, authentication } from "../../FirebaseLink";
import React, { useState } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import "../../CSS/JSA.css";
import Loading from "../../Loading";
export default function JSAFooter(props) {
  const [isLoading, setIsLoading] = useState(false);
  const createTimesheet = (Timesheet) => {
    setIsLoading(true);
    //Job.push(Timesheet);
    const docRef = doc(db, props.jobNum, props.fileId);
    //const reference = ref(db, "TestJob101");
    getDoc(docRef);

    if (props.signature === null) {
      window.alert("Signature Required");
      setIsLoading(false);
    } else if (
      props.T1[0].Table.Date === undefined ||
      props.T1[0].Table.Date === null ||
      props.T1[0].Table.Date === ""
    ) {
      window.alert("Date Required");
      setIsLoading(false);
    } else {
      setDoc(docRef, {
        T1: props.T1,
        T2: props.T2,
        T3: props.T3,
        T4: props.T4,
        T5: props.T5,
        T6: props.T6,
        T7: props.T7,
        T8: props.T8,
        T9: props.T9,
        T10: props.T10,
        T11: props.T11,
        Type: "JSA",
        baseId: props.fileId,
        signature: props.signature,
        id: props.id,
        TypeExtra: props.TypeExtra,
        lastUpdatedBy: authentication.currentUser.email,
        hasBeenUpdated: "yes",
      })
        .then(() => {
          setIsLoading(false);
          window.alert("Success");
        })
        .catch((error) => {
          setIsLoading(false);
          window.alert("Submit Failed");
        });
    }
  };
  const toggleOverlay = () => {
    props.setVisible(!props.visible);
  };
  return (
    <div className="footerPage">
      {isLoading ? <Loading /> : <div></div>}
      <div className="footerPageSig">
        <div className="sigDiv">
          <button
            title="Signature"
            className="SubBtn"
            onClick={() => toggleOverlay()}
          >
            <div className="loginText">Signature</div>
          </button>

          <img alt="" className="prevFF" src={props.signature} />
        </div>
      </div>
      <button
        className="SubBtn"
        title="Submit"
        onClick={() => {
          createTimesheet({});
        }}
      >
        <div className="loginText">Submit</div>
      </button>
    </div>
  );
}
