import React, { useState, useEffect } from "react";
import "../../CSS/FRT7.css";
export default function FRT7(props) {
  const [Line0, setLine0] = useState({});
  useEffect(() => {
    if (Object.keys(Line0).length !== 0) {
      props.setT7(props.T7, (props.T7[0] = { Line0 }));
    } else if (props.T7 !== undefined) {
      if (props.T7[0] !== undefined) {
        setLine0(props.T7[0].Line0);
      }
    }
  }, [props, Line0]);
  return (
    <div className="bodyF7">
      <div className="ColumnTitleF7">Overview of Progress</div>
      <div className="ColumnF7">
        <div className="RowF7">
          <textarea
            className="textInputTestF7"
            placeholder=""
            value={Line0.Comment}
            onChange={(event) => {
              setLine0({ ...Line0, Comment: event.target.value });
            }}
          />
        </div>
      </div>
    </div>
  );
}
