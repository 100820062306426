import { db } from "./FirebaseLink";
import React from "react";
import "./CSS/NewJob.css";
import { doc, getDoc, setDoc } from "firebase/firestore";
import Loading from "./Loading";
export default class NewTimesheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobNum: "",
      isLoading: false,
    };
  }

  render() {
    const onInputchange = (e) => {
      this.setState({
        [e.target.name]: e.target.value,
      });
    };
    const DoBoth = async () => {
      await NewTimesheet();
    };

    const NewTimesheet = async (company) => {
      this.setState({
        isLoading: true,
      });
      if (this.state.jobNum === "") {
        alert("Please Insert Job Number");
      } else if (this.state.jobNum.includes("/")) {
        alert("Job number cannot include any /");
      } else if (this.state.jobNum.includes("%20")) {
        alert("Job number cannot include any %20");
      } else if (this.state.jobNum.includes("_")) {
        alert("Job number cannot include any _");
      } else {
        //const ref = db.collection(window.localStorage.getItem("Company")).doc();
        /*await db
          .collection(window.localStorage.getItem("Company"))
          .doc(ref._delegate._key.path.segments[1])
          .set({
            JobNum:
              this.state.jobNum + "_" + window.localStorage.getItem("Company"),
            baseid: ref._delegate._key.path.segments[1],
          });*/
        await db
          .collection(
            window.localStorage.getItem("Company") + "_" + this.state.jobNum
          )
          .limit(1)
          .get()
          .then(async (snapshot) => {
            if (snapshot.size === 0) {
              await db
                .collection(
                  window.localStorage.getItem("Company") +
                    "_" +
                    this.state.jobNum
                )
                .add({})
                .then((docRef) => {
                  setDoc(docRef, {
                    TimesheetHeader: {},
                    TimesheetLines: { line0: ["", "", "", "", "", "", ""] },
                    Comment: "",
                    Type: "Timesheet",
                    baseId: docRef.id,
                    TypeExtra: "Template",
                    id: 0,
                  });
                });

              await db
                .collection(
                  window.localStorage.getItem("Company") +
                    "_" +
                    this.state.jobNum
                )
                .add({})
                .then((docRef) => {
                  setDoc(docRef, {
                    Type: "Foreman Report",
                    TypeExtra: "Template",
                    baseId: docRef.id,
                    Header: [{ Line0: {} }],
                    T1: [{ Line0: {} }, { Line1: {} }],
                    T2: [{ Line0: {} }],
                    T3: [{ Line0: {} }],
                    T4: [{ Line0: {} }],
                    T5: [{ Line0: {} }],
                    T6: [{ Line0: {} }, { Line1: {} }],
                    T7: [{ Line0: {} }],
                    id: 1,
                  });
                });

              await db
                .collection(
                  window.localStorage.getItem("Company") +
                    "_" +
                    this.state.jobNum
                )
                .add({})
                .then((docRef) => {
                  setDoc(docRef, {
                    Type: "JSA",
                    TypeExtra: "Template",
                    baseId: docRef.id,
                    T1: [{ Table: {} }],
                    T2: [{ Table: {} }],
                    T3: [{ Table: {} }],
                    T4: [{ Table: {} }],
                    T5: [{ Table: {} }],
                    T6: [{ Table: {} }],
                    T7: [{ Table: {} }],
                    T8: [{ Table: { Row0: ["", ""] } }],
                    T9: [{ Line0: { Row0: [""] } }],
                    T10: [{ Line0: { Row0: [""] } }],
                    T11: [{ Line0: { Row0: ["", "", ""] } }],
                    id: 2,
                  });
                });
              /*await db
                .collection(window.localStorage.getItem("Company"))
                .doc(ref._delegate._key.path.segments[1])
                .set({
                  JobNum:
                    window.localStorage.getItem("Company") +
                    "_" +
                    this.state.jobNum,
                  baseid: ref._delegate._key.path.segments[1],
                });*/
              const docRef = doc(db, window.localStorage.getItem("Company"), "master");
              let temp = await getDoc(docRef);
              console.log("Test 1", temp.data());
              let tempAry = temp.data().Jobs;
              tempAry.push(window.localStorage.getItem("Company") + "_" + this.state.jobNum);
              console.log("Test 2", tempAry);
              setDoc(docRef, { Jobs: tempAry });
            } else {
              alert("Job already exists");
            }
          });
      }
      this.setState({
        isLoading: false,
      });
    };
    return (
      <div className="containerNewJob" key={1}>
        {this.state.isLoading ? <Loading /> : <div></div>}
        <div className="newJobBtn" key={1}>
          <input
            name="jobNum"
            className="inputNewJob"
            placeholder="New Job Number"
            value={this.state.jobNum}
            onChange={onInputchange}
            //onChange={(e) => this.state.setState(jo)}
          />
          <button
            key={1}
            className="SubBtnNewJob"
            onClick={() => DoBoth()}
            title="Submit"
            color="white"
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}
