import React from "react";
import { useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import "../CSS/Sig.css";

export const SignatureCapture = (props) => {
  const [Sig, setSig] = useState(null);

  const toggleOverlay = () => {
    props.setVisible(!props.visible);
  };
  const clear = () => {
    Sig.clear();
  };
  const trim = () => {
    if (props.setSign !== undefined) {
      props.setSign(Sig.getCanvas().toDataURL("image/png"));
    }
    if (props.setTable !== undefined) {
      var temp = props.Table[props.Keys][0];
      var temp2;
      temp2 = [temp];
      temp2[1] = Sig.getCanvas().toDataURL("image/png");
      props.setTable({
        ...props.Table,
        [props.Keys]: temp2,
      });
    }
    if (props.setLine !== undefined) {
      props.setLine({
        ...props.Line,
        signature: Sig.getCanvas().toDataURL("image/png"),
      });
    }
  };
  return (
    <div className="GCSig">
      <div className="sigCont">
        <SignatureCanvas
          canvasProps={{ className: "sigCanvas" }}
          ref={(ref) => {
            setSig(ref);
          }}
        />
      </div>
      <div className="sigBtns">
        <button className="buttonsSig" onClick={clear}>
          Clear
        </button>
        <button className="buttonsSig" onClick={trim}>
          Save
        </button>
        <button
          title="Close"
          color="green"
          className="toggleOverlaySig"
          onClick={() => toggleOverlay()}
        >
          Close
        </button>
      </div>
    </div>
  );
};
