import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./CSS/Footer.css";
import { useEffect } from "react";
import Logo from "../LoginLogoNoBack.png";
import { AiOutlineCopyright } from "react-icons/ai";
import { MdPrivacyTip } from "react-icons/md";
import { RiPagesLine } from "react-icons/ri";

export default function Footer(props) {
  const [visibleNav, setVisibleNav] = useState(false);

  useEffect(() => {
    if (
      window.localStorage.getItem("isLoggedIn") !== true &&
      window.localStorage.getItem("isLoggedIn") !== "true"
    ) {
      setVisibleNav(false);
    } else {
      setVisibleNav(true);
      props.setAuthenticated(true);
    }
  }, [visibleNav, props.authenticated]);
  return (
    <footer className="ContainerFooter">
      <ul className="FootBtnFlex">
        <li className="FootLogo">
          <img src={Logo} className="LogoFooter" alt="" />
        </li>
        <li className="FootBtnSecond">
          <ul className="FootList">
            <li className="FootBtnSecond">
              <div className="FootBtn">
                <AiOutlineCopyright className="FootIcon" />
                <div className="FootBtnText-Copy">
                  Copyright 2022 Tanner Schafer - All Rights Reserved
                </div>
              </div>
            </li>

            <li className="FootBtnSecond">
              <Link to={"terms"} className="FootBtn">
                <RiPagesLine className="FootIcon" />
                <div className="FootBtnText">Terms and Conditions</div>
              </Link>
            </li>
            <li className="FootBtnSecond">
              <Link
                to={"Privacy.html"}
                onClick={"refreshPage()"}
                className="FootBtn"
              >
                <MdPrivacyTip className="FootIcon" />
                <div className="FootBtnText">Privacy</div>
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </footer>
  );
}
