import React from "react";
import "./CSS/Loading.css";

export default function Loading() {
  return (
    <div className="spinner-global-container">
      <div className="spinner-container">
        <div className="loading-spinner-under">
          <div className="loading-spinner"></div>
        </div>
      </div>
    </div>
  );
}
