import React, { useState, useEffect } from "react";
import "../../CSS/JSAT7.css";
import { ImCheckmark } from "react-icons/im";
export default function JSAT7(props) {
  const [Table, setTable] = useState({});
  useEffect(() => {
    if (Object.keys(Table).length !== 0) {
      props.setT7(props.T7, (props.T7[0] = { Table }));
    } else if (props.T7 !== undefined) {
      if (props.T7[0] !== undefined) {
        setTable(props.T7[0].Table);
      }
    }
  }, [props, Table]);
  return (
    <div className="body">
      <div className="ColumnTitle">
        <div className="Title">
          <div className="TitleText1">ROW Conditions:</div>
        </div>
      </div>
      <div className="Column">
        <div className="Row">
          <div className="TitleText1">Dry</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (Table.Dry !== null || Table.Dry !== undefined) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Dry: !Table.Dry,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Dry: true,
                });
              }
            }}
          >
            {Table.Dry ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>
        <div className="Row">
          <div className="TitleText1">Rocky</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (Table.Rocky !== null || Table.Rocky !== undefined) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Rocky: !Table.Rocky,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Rocky: true,
                });
              }
            }}
          >
            {Table.Rocky ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>

        <div className="Row">
          <div className="TitleText1">Muddy</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (Table.Muddy !== null || Table.Muddy !== undefined) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Muddy: !Table.Muddy,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Muddy: true,
                });
              }
            }}
          >
            {Table.Muddy ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>

        <div className="Row">
          <div className="TitleText1">Sandy</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (Table.Sandy !== null || Table.Sandy !== undefined) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Sandy: !Table.Sandy,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Sandy: true,
                });
              }
            }}
          >
            {Table.Sandy ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>

        <div className="Row">
          <div className="TitleText1">Ice/Snow</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (Table.Ice_Snow !== null || Table.Ice_Snow !== undefined) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Ice_Snow: !Table.Ice_Snow,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Ice_Snow: true,
                });
              }
            }}
          >
            {Table.Ice_Snow ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>

        <div className="Row">
          <div className="TitleText1">Steep Slope</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (Table.SteepSlope !== null || Table.SteepSlope !== undefined) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  SteepSlope: !Table.SteepSlope,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Ice_Snow: true,
                });
              }
            }}
          >
            {Table.SteepSlope ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
