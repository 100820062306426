import React, { useState } from "react";
//import * as DocumentPicker from "expo-document-picker";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getStorage } from "firebase/storage";
import { db, firebaseApp } from "./FirebaseLink";
import "./CSS/NewOQ.css";
import { useFilePicker } from "use-file-picker";
import Loading from "./Loading";

const NewOQ = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openFileSelector, { filesContent }] = useFilePicker({
    readAs: "DataURL",
    accept: ".pdf",
  });
  const DoBoth = async () => {
    if (filesContent !== undefined) {
      //    /*
      const FBstorage = getStorage(firebaseApp, "gs://sfms-ce695.appspot.com");
      const Ref = ref(FBstorage, props.jobNum + "/" + filesContent[0].name);
      getDownloadURL(Ref).then(
        async () => {
          window.alert(
            "Either this file has already been uploaded or it shares the same file name as another file"
          );
        },
        async () => {
          const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
              resolve(xhr.response);
            };
            xhr.onerror = function (e) {
              reject(new TypeError("Network request failed"));
            };
            xhr.responseType = "blob";
            xhr.open("GET", filesContent[0].content, true);
            xhr.send(null);
          });
          uploadBytes(Ref, blob).then(async (snapshot) => {
            getDownloadURL(snapshot.ref).then(async (downloadURL) => {
              await NewOQ(downloadURL, filesContent[0].name);
            });
          });
        }
      );
    }
  };

  const NewOQ = async (temp, name) => {
    setIsLoading(true);
    const ref = db.collection(props.jobNum).doc();
    await db
      .collection(props.jobNum)
      .doc(ref._delegate._key.path.segments[1])
      .set({
        Type: "OQ",
        baseId: ref._delegate._key.path.segments[1],
        URI: temp,
        name: name,
        id: props.job.length,
      });

    setIsLoading(false);
  };
  return (
    <div className="containerBar" key={1}>
      {isLoading ? <Loading /> : <div></div>}
      <div className="newJobOQ" key={2}>
        <button
          key={3}
          className="existingJobBtn2TSOQ"
          onClick={() => openFileSelector()}
          title="Pick OQ"
          color="white"
        >
          Pick File
        </button>
        <button
          key={4}
          className="existingJobBtn2TSOQ"
          onClick={() => DoBoth()}
          title="Upload OQ"
          color="white"
        >
          Upload File
        </button>
      </div>
    </div>
  );
};

export default NewOQ;
