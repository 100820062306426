import { db } from "../../FirebaseLink";
import React, { useState, useEffect } from "react";
import "../../CSS/JSA.css";
import JSAT1 from "./JSAT1";
import JSAT2 from "./JSAT2";
import JSAT3 from "./JSAT3";
import JSAT4 from "./JSAT4";
import JSAT5 from "./JSAT5";
import JSAT6 from "./JSAT6";
import JSAT7 from "./JSAT7";
import JSAT8 from "./JSAT8";
import JSAT9 from "./JSAT9";
import JSAT10 from "./JSAT10";
import JSAT11 from "./JSAT11";
import JSAFooter from "./JSAFooter";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { SignatureCapture } from "../SignatureCapture";
import Loading from "../../Loading";

const JSA = React.forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = useState(false);
  const [Job, setJob] = useState([]);
  const [signature, setSign] = useState(null);
  const [visible, setVisible] = useState(false);
  const [T1, setT1] = useState([]);
  const [T2, setT2] = useState([]);
  const [T3, setT3] = useState([]);
  const [T4, setT4] = useState([]);
  const [T5, setT5] = useState([]);
  const [T6, setT6] = useState([]);
  const [T7, setT7] = useState([]);
  const [T8, setT8] = useState([]);
  const [T9, setT9] = useState([]);
  const [T10, setT10] = useState([]);
  const [T11, setT11] = useState([]);
  const [JobNum] = useState(
    window.location.href.split("/")[3].replace("%20", " ")
  );
  const [fileId] = useState(window.location.href.split("/")[5]);
  const [file, setFile] = useState({});
  const [onLoad, setOnLoad] = useState(true);
  const [startDate, setStartDate] = useState();

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      if (onLoad === true) {
        (async () => {
          setIsLoading(true);
          const docRef = doc(db, JobNum, fileId);
          const docSnap = await getDoc(docRef);
          setOnLoad(false);
          setFile(docSnap.data());
          setIsLoading(false);
        })();
      }
      if (file !== undefined) {
        if (file.T1 !== undefined) {
          setT1(file.T1);
          if (file.T1[0].Date !== undefined) {
            const tempDate = new Date(file.T1[0].Table.Date);
            setStartDate(tempDate);
          }
        }
        if (file.T2 !== undefined) {
          setT2(file.T2);
        }
        if (file.T3 !== undefined) {
          setT3(file.T3);
        }
        if (file.T4 !== undefined) {
          setT4(file.T4);
        }
        if (file.T5 !== undefined) {
          setT5(file.T5);
        }
        if (file.T6 !== undefined) {
          setT6(file.T6);
        }
        if (file.T7 !== undefined) {
          setT7(file.T7);
        }
        if (file.T8 !== undefined) {
          setT8(file.T8);
        }
        if (file.T9 !== undefined) {
          setT9(file.T9);
        }
        if (file.T10 !== undefined) {
          setT10(file.T10);
        }
        if (file.T11 !== undefined) {
          setT11(file.T11);
        }

        if (file.signature !== undefined) {
          setSign(file.signature);
        }
      }
    }
    return () => {
      // cancel the subscription
      isSubscribed = false;
    };
  }, [file]);

  /*const fetchJob = async () => {
    var Job = [];
    const response = db.collection(props.route.params.file.JobNum);
    const data = await response.get();
    Job = data.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      baseId: doc.id,
    }));
    data.docs.forEach((item) => {
      setJob([...Job]);
    });
  };*/
  /*useEffect(() => {
    fetchJob();
    if (props.route.params.file.signature !== undefined) {
      setSign(props.route.params.file.signature);
    }
    if (props.route.params.file.T1 !== undefined) {
      setT1(props.route.params.file.T1);
    }
    if (props.route.params.file.T2 !== undefined) {
      setT2(props.route.params.file.T2);
    }
    if (props.route.params.file.T3 !== undefined) {
      setT3(props.route.params.file.T3);
    }
    if (props.route.params.file.T4 !== undefined) {
      setT4(props.route.params.file.T4);
    }
    if (props.route.params.file.T5 !== undefined) {
      setT5(props.route.params.file.T5);
    }
    if (props.route.params.file.T6 !== undefined) {
      setT6(props.route.params.file.T6);
    }
    if (props.route.params.file.T7 !== undefined) {
      setT7(props.route.params.file.T7);
    }
    if (props.route.params.file.T8 !== undefined) {
      setT8(props.route.params.file.T8);
    }
    if (props.route.params.file.T9 !== undefined) {
      setT9(props.route.params.file.T9);
    }
    if (props.route.params.file.T10 !== undefined) {
      setT10(props.route.params.file.T10);
    }
    if (props.route.params.file.T11 !== undefined) {
      setT11(props.route.params.file.T11);
    }
  }, []);*/

  /*const SignInScroll = () => {
    setScrollEnabled(!scrollEnabled);
  };*/
  return visible ? (
    <SignatureCapture
      visible={visible}
      setVisible={setVisible}
      Sign={signature}
      setSign={setSign}
    />
  ) : (
    <div className="globalContainerJSA" ref={ref}>
      {isLoading ? <Loading /> : <div></div>}
      {/*<button
        className="SubBtn"
        title="Lock"
        
        onClick={() => SignInScroll()}
      >
        <div className="LockText">Lock Scroll</div>
  </button>*/}
      <div>
        <div className="RowOne">
          <div className="header">
            <JSAT1
              T1={T1}
              setT1={setT1}
              id={0}
              setStartDate={setStartDate}
              startDate={startDate}
            />
          </div>
        </div>
        <div className="RowTwo">
          <div className="BJSAT1">
            <JSAT2 T2={T2} setT2={setT2} id={1} />
          </div>
        </div>
        <div className="RowThree">
          <div className="BJSAT2">
            <JSAT3 T3={T3} setT3={setT3} id={2} />
          </div>
        </div>
        <div className="RowFourJSA">
          <div className="BJSAT3">
            <JSAT4 T4={T4} setT4={setT4} id={3} />
          </div>
        </div>
        <div className="RowFive">
          <div className="BJSAT4">
            <JSAT5 T5={T5} setT5={setT5} id={4} />
          </div>
        </div>

        <div className="RowSix">
          <div className="BJSAT5">
            <JSAT6 T6={T6} setT6={setT6} id={5} />
          </div>
        </div>
        <div className="RowSeven">
          <div className="BJSAT6">
            <JSAT7 T7={T7} setT7={setT7} id={6} />
          </div>
        </div>
        <div className="RowEight">
          <div className="BJSAT7">
            <JSAT8 T8={T8} setT8={setT8} id={7} setSign={setSign} />
          </div>
        </div>
        <div className="RowNineTen">
          <div className="RowNine">
            <div className="BJSAT8">
              <JSAT9 T9={T9} setT9={setT9} id={8} setSign={setSign} />
            </div>
          </div>

          <div className="RowTen">
            <div className="BJSAT9">
              <JSAT10 T10={T10} setT10={setT10} id={9} setSign={setSign} />
            </div>
          </div>
        </div>
        <div className="RowEleven">
          <div className="BJSAT10">
            <JSAT11 T11={T11} setT11={setT11} id={10} setSign={setSign} />
          </div>
        </div>

        <JSAFooter
          jobNum={JobNum}
          T1={T1}
          T2={T2}
          T3={T3}
          T4={T4}
          T5={T5}
          T6={T6}
          T7={T7}
          T8={T8}
          T9={T9}
          T10={T10}
          T11={T11}
          //route={props.route}
          fileId={fileId}
          visible={visible}
          setVisible={setVisible}
          signature={signature}
          id={file.id}
          TypeExtra={file.TypeExtra}
          //user={props.route.params.file.user}
        />
      </div>
    </div>
  );
});
export default JSA;
