import React from "react";
import { useState } from "react";
import Jobs from "./Jobs";
import NewJob from "./NewJobButton";
import Search from "./Search";
import "./CSS/Home.css";
export default function Home(props) {
  const [searchPhrase, setSearchPhrase] = useState("");
  const [clicked, setClicked] = useState(false);

  return (
    <div className="Gloablcontainer">
      <Search
        searchPhrase={searchPhrase}
        setSearchPhrase={setSearchPhrase}
        clicked={clicked}
        setClicked={setClicked}
      />

      <Jobs searchPhrase={searchPhrase} user={"props.route.params"} />

      <NewJob className="blank" />
    </div>
  );
}
