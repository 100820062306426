import { db } from "./FirebaseLink";
import React, { useState, useEffect } from "react";
import {
  query,
  orderBy,
  onSnapshot,
  collection,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { Link } from "react-router-dom";
import "./CSS/Jobs.css";
import { fetchUsersCompany } from "./FirebaseLink";
import Loading from "./Loading";
import { AiOutlineMenu } from "react-icons/ai";

export default function Jobs(props) {
  const [Jobs, setJobs] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const Delete = async (temp, company) => {
    const docSnap = await getDoc(
      doc(db, "Users", window.localStorage.getItem("BaseId"))
    );
    if (docSnap.data().Admin === true) {
      if (window.confirm("Are you sure you wish to delete this job?")) {
        /*await db
          .collection(window.localStorage.getItem("Company"))
          .doc(temp.baseid)
          .delete();*/

        const updatedJobs = Jobs.filter((job) => job !== temp);
        // Updating the Jobs state with the updatedJobs array
        setJobs(updatedJobs);

        const docRef = doc(
          db,
          window.localStorage.getItem("Company"),
          "master"
        );
        setDoc(docRef, { Jobs: updatedJobs });
        //await db.collection(props.company).doc(temp.baseid).delete();
        await deleteCollection(temp);
      }
      //await deleteCollection(temp.JobNum);
    } else {
      window.alert("invalid permissions");
    }
  };
  const deleteCollection = async (path) => {
    setIsLoading(true);
    db.collection(path)
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.forEach((snapshot) => {
          snapshot.ref.delete();
        });
      })
      .then(() => setIsLoading(false));
  };
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await fetchUsersCompany().catch(() => {
        setIsLoading(false);
      });
      if (
        window.localStorage.getItem("Company") !== "" ||
        window.localStorage.getItem("Company") !== undefined
      ) {
        const unsubscribe = onSnapshot(
          query(doc(db, window.localStorage.getItem("Company"), "master")),
          (snapshot) => {
            //const tempAry = snapshot.docs.map((doc) => doc.data());
            console.log(snapshot.data().Jobs);
            setJobs(snapshot.data().Jobs);
            console.log("2", Jobs);
          }
        );

        return () => {
          unsubscribe();
        };
      } else {
        await fetchUsersCompany();
      }
    };
    fetchData().then(() => setIsLoading(false));
  }, []);
  return (
    <div className="jobsCont">
      {isLoading ? <Loading /> : <div></div>}
      {Jobs.map((job) => {
        if (job !== undefined) {
          if (
            job
              .split("_")[1]
              .toLowerCase()
              .includes(props.searchPhrase.toLowerCase())
          ) {
            //job.user = props.user;
            return (
              <div className="existingJob" key={job}>
                <Link
                  className="existingJobBtn"
                  to={job}
                  props={{ job: job }}
                  key={job}
                  color="white"
                  state={{ jobProp: job }}
                >
                  <div className="testTemp">
                    <AiOutlineMenu className="menuIcon" />
                    <div className="existingJobBtnDiv">
                      <div className="borderBottom"></div>
                      <div className="borderRight"></div>
                      {job.split(window.localStorage.getItem("Company") + "_")}
                    </div>
                  </div>
                </Link>
                {visible ? (
                  <div className="existingJob2JobsDiv">
                    <button
                      className="existingJob2Jobs"
                      onClick={() => Delete(job)}
                      color="white"
                    >
                      X
                    </button>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            );
          } else {
            return <></>;
          }
        } else {
          return <></>;
        }
      })}
      <button
        className="EditJobs"
        onClick={() => setVisible(!visible)}
        title={"Edit"}
        color="white"
      >
        Edit
      </button>
    </div>
  );
}
