import { db } from "../../FirebaseLink";
import React from "react";
import "../../CSS/Duplicate.css";
import Loading from "../../Loading";

export default class Duplicate extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
    };
  }
  render() {
    const DoBoth = async () => {
      await NewTimesheet();
    };
    const NewTimesheet = async () => {
      this.setState({
        isLoading: true,
      });
      const ref = db.collection(this.props.jobNum).doc();
      await db
        .collection(this.props.jobNum)
        .doc(ref._delegate._key.path.segments[1])
        .set({
          Comment: this.props.file.Comment,
          JobNum: this.props.file.JobNum,
          TimesheetHeader: this.props.file.TimesheetHeader,
          TimesheetLines: this.props.file.TimesheetLines,
          Type: this.props.file.Type,
          FRsignature: this.props.file.FRsignature,
          CRsignature: this.props.file.CRsignature,
          Csignature: this.props.file.Csignature,
          TypeExtra: "null",
          baseId: ref._delegate._key.path.segments[1],
          id: this.props.job.length,
          hasBeenUpdated: "dup",
        });
      this.setState({
        isLoading: false,
      });
    };
    return (
      <div className="containerBar" key={1}>
        {this.state.isLoading ? <Loading /> : <div></div>}
        <div className="newJob" key={1}>
          <button
            className="existingJobBtnDup"
            onClick={() => DoBoth()}
            title="Duplicate Template"
            color="white"
          >
            Duplicate Template
          </button>
        </div>
      </div>
    );
  }
}
