import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import JSA from "./JSA";
const JSAPDF = () => {
  const componentRef = useRef();
  return (
    <div className="TimesheetGC">
      <JSA ref={componentRef} />
      <ReactToPrint
        trigger={() => (
          <div className="printCont">
            <button className="printBtn">Convert to PDF</button>
          </div>
        )}
        content={() => componentRef.current}
      />
    </div>
  );
};
export default JSAPDF;
