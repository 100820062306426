import React, { useState, useEffect } from "react";
import "../../CSS/JSAT2.css";
export default function JSAT2(props) {
  const [Table, setTable] = useState({});
  useEffect(() => {
    if (Object.keys(Table).length !== 0) {
      props.setT2(props.T2, (props.T2[0] = { Table }));
    } else if (props.T2 !== undefined) {
      if (props.T2[0] !== undefined) {
        setTable(props.T2[0].Table);
      }
    }
  }, [props, Table]);
  return (
    <div className="body">
      <div className="Column">
        <div className="Row">
          <div className="TitleText2">Description of work to be performed:</div>
        </div>
      </div>
      <div className="Column">
        <div className="Row">
          <textarea
            className="textInputTest"
            placeholder=""
            value={Table.Description}
            onChange={(event) => {
              setTable({ ...Table, Description: event.target.value });
            }}
          />
        </div>
      </div>
    </div>
  );
}
