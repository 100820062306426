import React, { useState, useEffect } from "react";
import "../../CSS/TimesheetBody.css";
import { ImCheckmark } from "react-icons/im";

export default function TimesheetBody(props) {
  const [Table, setTable] = useState({});
  useEffect(() => {
    if (Object.keys(Table).length !== 0) {
      props.setT8(Table);
    } else if (props.T8 !== undefined) {
      setTable(props.T8);
    }
  }, [props, Table]);
  const addRow = () => {
    var rows = ["", "", "", "", "", "", "", ""];
    var temp = Object.keys(Table).length;

    var Keys = "Line" + temp;
    setTable({
      ...Table,
      [Keys]: rows,
    });
    //rows.push({ name: "", sig: "", num: temp });
    //setRows([...rows]);
  };
  return (
    <div className="body">
      <button className="SubBtn" title="Lock" onClick={() => addRow()}>
        <div className="LockText">Add Row</div>
      </button>
      <div className="bodyHeaderBodyWeb">
        <div className="bGridLarge">
          <div className="bGridColumns">
            <div className="textInputHeaderHeader">Name</div>
          </div>
        </div>
        <div className="bGridSmall">
          <div className="bGridColumns">
            <div className="textInputHeaderHeader">Occ</div>
          </div>
        </div>
        <div className="bGridSmall">
          <div className="bGridColumns">
            <div className="textInputHeaderHeader">Hrs.</div>
          </div>
        </div>
        <div className="bGridSmall">
          <div className="bGridColumns">
            <div className="textInputHeaderHeader">P/U</div>
          </div>
        </div>
        <div className="bGridSmall">
          <div className="bGridColumns">
            <div className="textInputHeaderHeader">Rig</div>
          </div>
        </div>
        <div className="bGridSmall">
          <div className="bGridColumns">
            <div className="textInputHeaderHeader">P/D</div>
          </div>
        </div>
        <div className="bGridMedium">
          <div className="bGridColumns">
            <div className="textInputHeaderHeader">Equip No.</div>
          </div>
        </div>
        <div className="bGridLarge">
          <div className="bGridColumns">
            <div className="textInputHeaderHeader">Equip Description</div>
          </div>
        </div>
      </div>
      <div className="ColumnTS">
        {Object.keys(Table)
          .sort()
          .map((Keys, r) => (
            <div>
              <div className="bodyHeader">
                <div className="bodyHeaderBodyMobile">
                  <div className="bGridLarge">
                    <div className="bGridColumns">
                      <div className="textInputHeaderHeader">Name</div>
                    </div>
                  </div>
                  <div className="bGridSmall">
                    <div className="bGridColumns">
                      <div className="textInputHeaderHeader">Occ</div>
                    </div>
                  </div>
                  <div className="bGridSmall">
                    <div className="bGridColumns">
                      <div className="textInputHeaderHeader">Hrs.</div>
                    </div>
                  </div>
                  <div className="bGridSmall">
                    <div className="bGridColumns">
                      <div className="textInputHeaderHeader">P/U</div>
                    </div>
                  </div>
                  <div className="bGridSmall">
                    <div className="bGridColumns">
                      <div className="textInputHeaderHeader">Rig</div>
                    </div>
                  </div>
                  <div className="bGridSmall">
                    <div className="bGridColumns">
                      <div className="textInputHeaderHeader">P/D</div>
                    </div>
                  </div>
                  <div className="bGridMedium">
                    <div className="bGridColumns">
                      <div className="textInputHeaderHeader">Equip No.</div>
                    </div>
                  </div>
                  <div className="bGridLarge">
                    <div className="bGridColumns">
                      <div className="textInputHeaderHeader">
                        Equip Description
                      </div>
                    </div>
                  </div>
                </div>
                <div key={Keys} className="Line">
                  <div className="bGridLarge">
                    <input
                      className="textInputTestBody"
                      placeholder=""
                      value={Table[Keys][0]}
                      onChange={(e) => {
                        var temp3 = Table[Keys];
                        //[temp] = [event];
                        temp3[0] = e.target.value;
                        setTable({
                          ...Table,
                          [Keys]: temp3,
                        });
                      }}
                    />
                  </div>
                  <div className="bGridSmall">
                    <input
                      className="textInputTestBody"
                      placeholder=""
                      value={Table[Keys][1]}
                      onChange={(e) => {
                        var temp3 = Table[Keys];
                        //[temp] = [event];
                        temp3[1] = e.target.value;
                        setTable({
                          ...Table,
                          [Keys]: temp3,
                        });
                      }}
                    />
                  </div>
                  <div className="bGridSmall">
                    <input
                      className="textInputTestBody"
                      placeholder=""
                      value={Table[Keys][2]}
                      onChange={(e) => {
                        var temp3 = Table[Keys];
                        //[temp] = [event];
                        temp3[2] = e.target.value;
                        setTable({
                          ...Table,
                          [Keys]: temp3,
                        });
                      }}
                    />
                  </div>
                  <div className="bGridSmallBtn">
                    <button
                      className="TrueBtn"
                      title="Lock"
                      onClick={() => {
                        if (
                          Table[Keys][3] !== null ||
                          Table[Keys][3] !== undefined
                        ) {
                          var temp3 = Table[Keys];
                          //[temp] = [event];
                          temp3[3] = !Table[Keys][3];
                          setTable({
                            ...Table,
                            [Keys]: temp3,
                          });
                        } else {
                          //[temp] = [event];
                          temp3[3] = true;
                          setTable({
                            ...Table,
                            [Keys]: temp3,
                          });
                        }
                      }}
                    >
                      {Table[Keys][3] ? (
                        <div className="trueTS">
                          <div className="trueTextTS">
                            {" "}
                            <ImCheckmark className="checkmark" />
                          </div>
                        </div>
                      ) : (
                        <div className="falseTS">&nbsp;</div>
                      )}
                    </button>
                  </div>
                  <div className="bGridSmall">
                    <input
                      className="textInputTestBody"
                      placeholder=""
                      value={Table[Keys][4]}
                      onChange={(e) => {
                        var temp3 = Table[Keys];
                        //[temp] = [event];
                        temp3[4] = e.target.value;
                        setTable({
                          ...Table,
                          [Keys]: temp3,
                        });
                      }}
                    />
                  </div>
                  <div className="bGridSmallBtn">
                    <button
                      className="TrueBtn"
                      title="Lock"
                      onClick={() => {
                        if (
                          Table[Keys][5] !== null ||
                          Table[Keys][5] !== undefined
                        ) {
                          var temp3 = Table[Keys];
                          //[temp] = [event];
                          temp3[5] = !Table[Keys][5];
                          setTable({
                            ...Table,
                            [Keys]: temp3,
                          });
                        } else {
                          //[temp] = [event];
                          temp3[5] = true;
                          setTable({
                            ...Table,
                            [Keys]: temp3,
                          });
                        }
                      }}
                    >
                      {Table[Keys][5] ? (
                        <div className="trueTS">
                          <div className="trueTextTS">
                            {" "}
                            <ImCheckmark className="checkmark" />
                          </div>
                        </div>
                      ) : (
                        <div className="falseTS">&nbsp;</div>
                      )}
                    </button>
                  </div>
                  <div className="bGridMediumBody">
                    {
                      //here
                    }
                    <input
                      className="textInputTestBody"
                      placeholder=""
                      value={Table[Keys][6]}
                      onChange={(e) => {
                        var temp3 = Table[Keys];
                        //[temp] = [event];
                        temp3[6] = e.target.value;
                        setTable({
                          ...Table,
                          [Keys]: temp3,
                        });
                      }}
                    />
                  </div>
                  <div className="bGridLarge">
                    {
                      //here
                    }
                    <input
                      className="textInputTestBody"
                      placeholder=""
                      value={Table[Keys][7]}
                      onChange={(e) => {
                        var temp3 = Table[Keys];
                        //[temp] = [event];
                        temp3[7] = e.target.value;
                        setTable({
                          ...Table,
                          [Keys]: temp3,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
