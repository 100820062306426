import { db } from "../../FirebaseLink";
import React, { useState, useEffect } from "react";
import "../../CSS/ForemanReport.css";

import FRHeader from "./FRHeader";
import FRT1 from "./FRT1";
import FRT2 from "./FRT2";
import FRT3 from "./FRT3";
import FRT4 from "./FRT4";
import FRT5 from "./FRT5";
import FRT6 from "./FRT6";
import FRT7 from "./FRT7";
import ForemanFooter from "./ForemanFooter";
import { SignatureCapture } from "../SignatureCapture";
import { doc, getDoc, setDoc } from "firebase/firestore";
import Loading from "../../Loading";

const ForemanReport = React.forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = useState(false);
  const [Job, setJob] = useState([]);
  const [ForemanSignature, setForemanSign] = useState(null);
  const [ClientSignature, setClientSign] = useState(null);
  const [Header, setHeader] = useState([]);
  const [T1, setT1] = useState([]);
  const [T2, setT2] = useState([]);
  const [T3, setT3] = useState([]);
  const [T4, setT4] = useState([]);
  const [T5, setT5] = useState([]);
  const [T6, setT6] = useState([]);
  const [T7, setT7] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [JobNum] = useState(
    window.location.href.split("/")[3].replace("%20", " ")
  );
  const [fileId] = useState(window.location.href.split("/")[5]);
  const [file, setFile] = useState({});
  const [onLoad, setOnLoad] = useState(true);
  const [startDate, setStartDate] = useState();

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      if (onLoad === true) {
        (async () => {
          setIsLoading(true);
          const docRef = doc(db, JobNum, fileId);
          const docSnap = await getDoc(docRef);
          setOnLoad(false);
          setFile(docSnap.data());
          setIsLoading(false);
        })();
      }
      if (file.Header !== undefined) {
        setHeader(file.Header);
        if (file.Header[0].Line0.Date !== undefined) {
          const tempDate = new Date(file.Header[0].Line0.Date);
          setStartDate(tempDate);
        }
      }
      if (file.T1 !== undefined) {
        setT1(file.T1);
      }
      if (file.T2 !== undefined) {
        setT2(file.T2);
      }
      if (file.T3 !== undefined) {
        setT3(file.T3);
      }
      if (file.T4 !== undefined) {
        setT4(file.T4);
      }
      if (file.T5 !== undefined) {
        setT5(file.T5);
      }
      if (file.T6 !== undefined) {
        setT6(file.T6);
      }
      if (file.T7 !== undefined) {
        setT7(file.T7);
      }
      if (file.ForemanSignature !== undefined) {
        setForemanSign(file.ForemanSignature);
      }
      if (file.ClientSignature !== undefined) {
        setClientSign(file.ClientSignature);
      }
    }
    return () => {
      // cancel the subscription
      isSubscribed = false;
    };
  }, [file]);

  return visible ? (
    <SignatureCapture
      visible={visible}
      setVisible={setVisible}
      Sign={ForemanSignature}
      setSign={setForemanSign}
    />
  ) : visible2 ? (
    <SignatureCapture
      visible={visible2}
      setVisible={setVisible2}
      Sign={ClientSignature}
      setSign={setClientSign}
    />
  ) : (
    <div className="globalContainerJSA" ref={ref}>
      {isLoading ? <Loading /> : <div></div>}
      <div className="RowOne">
        <div className="header">
          <FRHeader
            Header={Header}
            setHeader={setHeader}
            setStartDate={setStartDate}
            startDate={startDate}
          />
        </div>
      </div>
      <div className="RowTwo">
        <div className="BT1">
          <FRT1 T1={T1} setT1={setT1} id={0} />
        </div>
      </div>
      <div className="RowThree">
        <div className="BT2">
          <FRT2 T2={T2} setT2={setT2} id={1} />
        </div>
      </div>
      <div className="RowFour">
        <div className="BT3">
          <FRT3 T3={T3} setT3={setT3} id={2} />
        </div>
        <div className="BT4">
          <FRT4 T4={T4} setT4={setT4} id={3} />
        </div>
        <div className="BT5">
          <FRT5 T5={T5} setT5={setT5} id={4} />
        </div>
      </div>
      <div className="RowFive">
        <div className="BT6">
          <FRT6 T6={T6} setT6={setT6} id={5} />
        </div>
      </div>
      <div className="RowSix">
        <div className="BT7">
          <FRT7 T7={T7} setT7={setT7} id={6} />
        </div>
      </div>
      <ForemanFooter
        fileId={fileId}
        jobNum={JobNum}
        T1={T1}
        T2={T2}
        T3={T3}
        T4={T4}
        T5={T5}
        T6={T6}
        T7={T7}
        Header={Header}
        visible={visible}
        setVisible={setVisible}
        visible2={visible2}
        setVisible2={setVisible2}
        ForemanSignature={ForemanSignature}
        ClientSignature={ClientSignature}
        id={file.id}
        TypeExtra={file.TypeExtra}
      />
    </div>
  );
});
export default ForemanReport;
