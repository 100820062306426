import React, { useState, useEffect } from "react";
import "../../CSS/JSAT1.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function JSAT1(props) {
  const [Table, setTable] = useState({});
  useEffect(() => {
    if (Object.keys(Table).length !== 0) {
      props.setT1(props.T1, (props.T1[0] = { Table }));
    } else if (props.T1 !== undefined) {
      if (props.T1[0] !== undefined) {
        setTable(props.T1[0].Table);
        if (props.T1[0].Table !== undefined) {
          if (props.T1[0].Table.Date !== undefined) {
            const tempDate = new Date(props.T1[0].Table.Date);
            props.setStartDate(tempDate);
          }
        }
      }
    }
  }, [props, Table]);
  return (
    <div className="body" key={1}>
      <div className="Column">
        <div className="RowJSAT1">
          <div className="TitleText2">Date:</div>
        </div>
        <div className="RowJSAT1">
          <DatePicker
            selected={props.startDate}
            onChange={(event) => {
              props.setStartDate(event);
              setTable({ ...Table, Date: event.toString() });
            }}
          />
        </div>

        <div className="RowJSAT1">
          <div className="TitleText2">Project #:</div>
        </div>

        <div className="RowJSAT1">
          <input
            className="textInputTest"
            placeholder=""
            value={Table.Project}
            onChange={(event) => {
              setTable({ ...Table, Project: event.target.value });
            }}
          />
        </div>

        <div className="RowJSAT1">
          <div className="TitleText2">Work Location:</div>
        </div>
        <div className="RowJSAT1">
          <input
            className="textInputTest"
            placeholder=""
            value={Table.Location}
            onChange={(event) => {
              setTable({ ...Table, Location: event.target.value });
            }}
          />
        </div>
      </div>

      <div className="Column">
        <div className="RowJSAT1">
          <div className="TitleText2">Client:</div>
        </div>
        <div className="RowJSAT1">
          <input
            className="textInputTest"
            placeholder=""
            value={Table.Client}
            onChange={(event) => {
              setTable({ ...Table, Client: event.target.value });
            }}
          />
        </div>
        <div className="RowJSAT1">
          <div className="TitleText2">Foreman/ Supervisor:</div>
        </div>
        <div className="RowJSAT1">
          <input
            className="textInputTest"
            placeholder=""
            value={Table.Foreman}
            onChange={(event) => {
              setTable({ ...Table, Foreman: event.target.value });
            }}
          />
        </div>
        <div className="RowJSAT1">
          <div className="TitleText2"># in Crew:</div>
        </div>
        <div className="RowJSAT1">
          <input
            className="textInputTest"
            placeholder=""
            value={Table.Num}
            onChange={(event) => {
              setTable({ ...Table, Num: event.target.value });
            }}
          />
        </div>
      </div>

      <div className="Column">
        <div className="RowJSAT1">
          <div className="TitleText2">GPS Coord:</div>
        </div>
        <div className="RowJSAT1">
          <input
            className="textInputTest"
            placeholder=""
            value={Table.Coords}
            onChange={(event) => {
              setTable({ ...Table, Coords: event.target.value });
            }}
          />
        </div>
        <div className="RowJSAT1">
          <div className="TitleText2">Nearest Intersection:</div>
        </div>
        <div className="RowJSAT1">
          <input
            className="textInputTest"
            placeholder=""
            value={Table.NearestIntersection}
            onChange={(event) => {
              setTable({
                ...Table,
                NearestIntersection: event.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className="Column">
        <div className="RowJSAT1">
          <div className="TitleText2">First Aid Person:</div>
        </div>
        <div className="RowJSAT1">
          <input
            className="textInputTest"
            placeholder=""
            value={Table.FirstAidPerson}
            onChange={(event) => {
              setTable({ ...Table, FirstAidPerson: event.target.value });
            }}
          />
        </div>
        <div className="RowJSAT1">
          <div className="TitleText2">Competent Person:</div>
        </div>
        <div className="RowJSAT1">
          <input
            className="textInputTest"
            placeholder=""
            value={Table.CompetentPerson}
            onChange={(event) => {
              setTable({ ...Table, CompetentPerson: event.target.value });
            }}
          />
        </div>
      </div>
      <div className="Column">
        <div className="RowJSAT1">
          <div className="TitleText2">Who will transport injured?</div>
        </div>
        <div className="RowJSAT1">
          <input
            className="textInputTest"
            placeholder=""
            value={Table.TransportPerson}
            onChange={(event) => {
              setTable({ ...Table, TransportPerson: event.target.value });
            }}
          />
        </div>
        <div className="RowJSAT1">
          <div className="TitleText2">Current Weather:</div>
        </div>
        <div className="RowJSAT1">
          <input
            className="textInputTest"
            placeholder=""
            value={Table.Weather}
            onChange={(event) => {
              setTable({ ...Table, Weather: event.target.value });
            }}
          />
        </div>
      </div>
      <div className="Column">
        <div className="RowJSAT1">
          <div className="TitleText2">
            FOR EMERGENCIES OR LIFE-THREATENING INJURIES,CALL 911*****
          </div>
        </div>
      </div>
    </div>
  );
}
