import React, { useState, useEffect } from "react";
import "../../CSS/JSAT10.css";
import T8Sig from "./T8Sig";

export default function JSAT10(props) {
  const [Line0, setLine0] = useState({});
  /*const [signature, setSign] = useState(null);
  const [signature0, setSign0] = useState(null);
  const [signature1, setSign1] = useState(null);
  const [signature2, setSign2] = useState(null);
  const [signature3, setSign3] = useState(null);
  const [signature4, setSign4] = useState(null);
  const [signature5, setSign5] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visible0, setVisible0] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [visible4, setVisible4] = useState(false);
  const [visible5, setVisible5] = useState(false);*/
  useEffect(() => {
    if (Object.keys(Line0).length !== 0) {
      props.setT10(props.T10, (props.T10[0] = { Line0 }));
    } else if (props.T10 !== undefined) {
      if (props.T10[0] !== undefined) {
        setLine0(props.T10[0].Line0);
      }
    }
  }, [props, Line0]);

  const addRow = () => {
    var rows = [""];
    var temp = Object.keys(Line0).length;
    var Keys = "Rows" + temp;
    setLine0({
      ...Line0,
      [Keys]: rows,
    });
    //rows.push({ name: "", sig: "", num: temp });
    //setRows([...rows]);
  };
  /* const toggleOverlay0 = () => {
    setVisible0(!visible0);
  };
  const toggleOverlay1 = () => {
    setVisible1(!visible1);
  };
  const toggleOverlay2 = () => {
    setVisible2(!visible2);
  };
  const toggleOverlay3 = () => {
    setVisible3(!visible3);
  };

  const toggleOverlay4 = () => {
    setVisible4(!visible4);
  };
  const toggleOverlay5 = () => {
    setVisible5(!visible5);
  };*/
  return (
    <div className="body2JSA8">
      <div className="bodyJSAT9">
        <div className="ColumnJSAT9">
          <div className="RowJSAT9">
            <div className="test101">Inspectors:</div>
          </div>
          {Object.keys(Line0)
            .sort()
            .map((Keys, r) => (
              <div className="RowJSA8" key={Keys}>
                <input
                  className="textInputTestSig"
                  placeholder=""
                  value={Line0[Keys][0]}
                  onChange={(event) => {
                    var temp3 = Line0[Keys];
                    temp3[0] = event.target.value;
                    //[temp] = [event];
                    setLine0({
                      ...Line0,
                      [Keys]: temp3,
                    });
                  }}
                />
              </div>
            ))}
          {/*<input
            className="textInputTestJSAT9"
            placeholder=""
            value={Line0.NamePrint}
            onChange={(event) => {
              setLine0({ ...Line0, NamePrint: event.target.value });
            }}
          />*/}
          {/*
        <div className="RowJSAT9">
          <input
            className="textInputTestJSAT9"
            placeholder=""
            value={Line1.NamePrint}
            onChange={(event) => {
              setLine1({ ...Line1, NamePrint: event.target.value });
            }}
          />
        </div>

        <div className="RowJSAT9">
          <input
            className="textInputTestJSAT9"
            placeholder=""
            value={Line2.NamePrint}
            onChange={(event) => {
              setLine2({ ...Line2, NamePrint: event.target.value });
            }}
          />
        </div>

        <div className="RowJSAT9">
          <input
            className="textInputTestJSAT9"
            placeholder=""
            value={Line3.NamePrint}
            onChange={(event) => {
              setLine3({ ...Line3, NamePrint: event.target.value });
            }}
          />
        </div>
        <div className="RowJSAT9">
          <input
            className="textInputTestJSAT9"
            placeholder=""
            value={Line4.NamePrint}
            onChange={(event) => {
              setLine4({ ...Line4, NamePrint: event.target.value });
            }}
          />
        </div>
        <div className="RowJSAT9">
          <input
            className="textInputTestJSAT9"
            placeholder=""
            value={Line5.NamePrint}
            onChange={(event) => {
              setLine5({ ...Line5, NamePrint: event.target.value });
            }}
          />
          </div>*/}
        </div>
        {
          <div className="ColumnJSAT9">
            <div className="RowJSAT9">
              <div className="test101">Signature:</div>
            </div>
            {Object.keys(Line0)
              .sort()
              .map((Keys, r) => (
                <T8Sig Table={Line0} setTable={setLine0} Keys={Keys} />
              ))}
          </div>
        }
      </div>
      <button className="AddBtnJSA10" title="Add Row" onClick={() => addRow()}>
        <div className="LockText">Add Row</div>
      </button>
    </div>
  );
}
