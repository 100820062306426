import React from "react";

export default function EditBtn(props) {
  return (
    <div className="EditTS" key={3}>
      <button
        className="existingJobBtn2TS"
        onClick={() => props.setVisible(!props.visible)}
        title={"Edit"}
        color="white"
        key={4}
      >
        Edit
      </button>
    </div>
  );
}
