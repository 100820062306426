import React, { useState } from "react";
import { Link } from "react-router-dom";
import { db, firebaseApp } from "../FirebaseLink";
import "../CSS/allOQ.css";
import { getStorage } from "firebase/storage";
import { ref, deleteObject } from "firebase/storage";

export default function AllOQ(props) {
  const [visible, setVisible] = useState(false);
  const Delete = async (temp) => {
    if (window.confirm("Are you sure you wish to delete this OQ?")) {
      await db.collection(props.jobNum).doc(temp.baseId).delete();
      const FBstorage = getStorage(firebaseApp, "gs://sfms-ce695.appspot.com");
      const Ref = ref(FBstorage, temp.JobNum + "/" + temp.name);
      deleteObject(Ref);
    }
    /*
Alert.alert("Delete OQ?", "Are you sure you wish to delete this OQ?", [
      {
        text: "Delete",
        style: "destructive",
        onClick: async () => {
          await db.collection(props.jobNum).doc(temp.baseId).delete();
          const FBstorage = getStorage(
            firebaseApp,
            "gs://sfms-ce695.appspot.com"
          );
          const Ref = ref(FBstorage, temp.JobNum + "/" + temp.name);
          deleteObject(Ref);
        },
      },
      {
        text: "Cancel",
        style: "cancel",
        // If the user confirmed, then we dispatch the action we blocked earlier
        // This will continue the action that had triggered the removal of the screen
        onClick: async () => {},
      },
    ]);
    */
  };
  if (props.job !== undefined) {
    return (
      <div className="Cont" key={1}>
        <div className="globalFiles" key={2}>
          {props.job.map((file, i) => {
            file.JobNum = props.jobNum;
            if (file.Type === "OQ") {
              return (
                <div className="GCTS" key={i + ".00"}>
                  <div className="containerTS" key={i + ".01"}>
                    <div className="existingJobTS" key={i + ".02"}>
                      <Link
                        className="existingJobBtnTS"
                        to={"/" + file.JobNum + "/OQ/" + file.baseId}
                        key={i + ".03"}
                        color="white"
                      >
                        <div
                          className="existingJobBtnTS"
                          title={file.name}
                          key={i + ".04"}
                        >
                          {file.name}
                        </div>
                      </Link>
                    </div>
                    {visible ? (
                      <div className="existingJob2TS" key={i + ".05"}>
                        <button
                          className="existingJobBtn3TS"
                          onClick={() => Delete(file)}
                          color="white"
                          key={i + ".06"}
                        >
                          -
                        </button>
                      </div>
                    ) : (
                      <div key={i + ".07"}></div>
                    )}
                  </div>
                </div>
              );
            } else {
              return <div key={i + ".08"}></div>;
            }
          })}
        </div>
        <div className="EditTS" key={3}>
          <button
            className="existingJobBtn2TS"
            onClick={() => setVisible(!visible)}
            title={"Edit"}
            color="white"
            key={4}
          >
            Edit
          </button>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
