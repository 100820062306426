import { db, authentication } from "../../FirebaseLink";
import React, { useState, useEffect } from "react";
import TimesheetLineComment from "./TimesheetComment";
import { doc, getDoc, setDoc } from "firebase/firestore";
import TimesheetBody from "./TimesheetBody";
import "../../CSS/Timesheet.css";
import { SignatureCapture } from "../SignatureCapture";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loading from "../../Loading";

const Timesheet = React.forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = useState(false);
  const [FRsignature, setFRSign] = useState(null);
  const [CRsignature, setCRSign] = useState(null);
  const [Csignature, setCSign] = useState(null);
  const [Comment, setComment] = useState("");
  const [Header, setHeader] = useState([]);
  const [Body, setBody] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [JobNum] = useState(
    window.location.href.split("/")[3].replace("%20", " ")
  );
  const [fileId] = useState(window.location.href.split("/")[5]);
  const [file, setFile] = useState({});
  const [onLoad, setOnLoad] = useState(true);
  const [startDate, setStartDate] = useState();
  const toggleOverlay = () => {
    setVisible(!visible);
  };
  const toggleOverlay2 = () => {
    setVisible2(!visible2);
  };
  const toggleOverlay3 = () => {
    setVisible3(!visible3);
  };
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      if (onLoad === true) {
        (async () => {
          setIsLoading(true);
          const docRef = doc(db, JobNum, fileId);
          const docSnap = await getDoc(docRef);
          setOnLoad(false);

          setFile(docSnap.data());
          setIsLoading(false);
        })();
      }
      if (file.TimesheetLines !== undefined) {
        setBody(file.TimesheetLines);
      }
      if (file.TimesheetHeader !== undefined) {
        setHeader(file.TimesheetHeader);
        if (file.TimesheetHeader.Date !== undefined) {
          const tempDate = new Date(file.TimesheetHeader.Date);
          setStartDate(tempDate);
        }
      }
      if (file.Comment !== undefined) {
        setComment(file.Comment);
      }
      if (file.FRsignature !== undefined) {
        setFRSign(file.FRsignature);
      }
      if (file.CRsignature !== undefined) {
        setCRSign(file.CRsignature);
      }
      if (file.Csignature !== undefined) {
        setCSign(file.Csignature);
      }
    }
    return () => {
      // cancel the subscription
      isSubscribed = false;
    };
  }, [file]);

  const createTimesheet = (Timesheet) => {
    //Job.push(Timesheet);\
    setIsLoading(true);
    const docRef = doc(db, JobNum, fileId);
    //const reference = ref(db, "TestJob101");
    getDoc(docRef);
    if (FRsignature === null) {
      window.alert("Foreman Signature Required");
      setIsLoading(false);
    } else if (
      Header.Date === undefined ||
      Header.Date === "" ||
      Header.Date == null
    ) {
      alert("Date Required");
      setIsLoading(false);
    } else {
      setDoc(docRef, {
        TimesheetHeader: Header,
        TimesheetLines: Body,
        Comment: Comment,
        Type: file.Type,
        baseId: file.baseId,
        FRsignature: FRsignature,
        CRsignature: CRsignature,
        Csignature: Csignature,
        lastUpdatedBy: authentication.currentUser.email,
        TypeExtra: file.TypeExtra,
        id: file.id,
        hasBeenUpdated: "yes",
      })
        .then(() => {
          setIsLoading(false);
          window.alert("Success");
        })
        .catch((error) => {
          setIsLoading(false);
          window.alert("Submit Failed");
        });
    }
  };
  return visible ? (
    <SignatureCapture
      visible={visible}
      setVisible={setVisible}
      Sign={FRsignature}
      setSign={setFRSign}
    />
  ) : visible2 ? (
    <SignatureCapture
      visible={visible2}
      setVisible={setVisible2}
      Sign={CRsignature}
      setSign={setCRSign}
    />
  ) : visible3 ? (
    <SignatureCapture
      visible={visible3}
      setVisible={setVisible3}
      Sign={Csignature}
      setSign={setCSign}
    />
  ) : (
    <div className="globalContainer" ref={ref}>
      {isLoading ? <Loading /> : <div></div>}
      <div className="header">
        <div className="hGridTitles">
          <div className="TextInputTwo">
            <div className="textInputHeader">Project: </div>
          </div>
          <div className="TextInputTwo">
            <div className="textInputHeader">Date: </div>
          </div>
          <div className="TextInputTwo">
            <div className="textInputHeader">Day: </div>
          </div>
          <div className="TextInputTwo">
            <div className="textInputHeader">Crew #: </div>
          </div>
        </div>

        <div className="hGridInputs">
          <div className="TextInputOne">
            <input
              className="textInputTestTS"
              placeholder=""
              value={Header.Project}
              onChange={(event) => {
                setHeader({ ...Header, Project: event.target.value });
              }}
            />
          </div>
          <div className="TextInputOne">
            <DatePicker
              selected={startDate}
              onChange={(event) => {
                setStartDate(event);
                setHeader({ ...Header, Date: event.toString() });
              }}
            />
          </div>
          <div className="TextInputOne">
            <input
              className="textInputTestTS"
              placeholder=""
              value={Header.Day}
              onChange={(event) => {
                setHeader({ ...Header, Day: event.target.value });
              }}
            />
          </div>
          <div className="TextInputOne">
            <input
              className="textInputTestTS"
              placeholder=""
              value={Header.Crew}
              onChange={(event) => {
                setHeader({ ...Header, Crew: event.target.value });
              }}
            />
          </div>
        </div>
      </div>
      <div className="body">
        <TimesheetBody T8={Body} setT8={setBody} />
      </div>

      <div className="footerDoc">
        <div className="footerdivTitle">
          <div className="footerDocTitle">Additional Comments</div>
        </div>
        <div className="footerdivContent">
          <TimesheetLineComment Comment={Comment} setComment={setComment} />
        </div>
      </div>
      <div className="footerPage">
        <div className="footerPageSig">
          {/*<div className="footerPageSigs">
            {/*<div className="prevFRImg">
              <div>Foreman</div>
              <img alt="" className="prevFF" src={FRsignature} />
            </div>
            <div className="prevFRImg">
              <div>Client Representative</div>
              <img alt="" className="prevFF" src={CRsignature} />
            </div>
            <div className="prevFRImg">
              <div>Company</div>
              <img alt="" className="prevFF" src={Csignature} />
            </div>
            </div>*/}
          <div className="sigDiv">
            <button
              title="Signature"
              className="SubBtnSig"
              onClick={() => toggleOverlay()}
            >
              <div className="loginText">Foreman Signature</div>
            </button>

            <img alt="" className="prevFF" src={FRsignature} />
          </div>
          <div className="sigDiv">
            <button
              title="Signature"
              className="SubBtnSig"
              onClick={() => toggleOverlay2()}
            >
              <div className="loginText">Client Representative Signature</div>
            </button>
            <img alt="" className="prevFF" src={CRsignature} />
          </div>
          <div className="sigDiv">
            <button
              title="Signature"
              className="SubBtnSig"
              onClick={() => toggleOverlay3()}
            >
              <div className="loginText">Company Signature</div>
            </button>
            <img alt="" className="prevFF" src={Csignature} />
          </div>
        </div>
        <button
          className="SubBtnTS"
          title="Submit"
          onClick={() => {
            createTimesheet({
              TempName: "TestTimesheet",
              TempBaseId: "001",
              TempId: "1",
            });
          }}
        >
          <div className="loginText">Submit</div>
        </button>
      </div>
    </div>
  );
});
export default Timesheet;
