import React, { useState, useEffect } from "react";
import "../../CSS/JSAT5.css";
import { ImCheckmark } from "react-icons/im";
export default function JSAT5(props) {
  const [Table, setTable] = useState({});
  useEffect(() => {
    if (Object.keys(Table).length !== 0) {
      props.setT5(props.T5, (props.T5[0] = { Table }));
    } else if (props.T5 !== undefined) {
      if (props.T5[0] !== undefined) {
        setTable(props.T5[0].Table);
      }
    }
  }, [props, Table]);
  return (
    <div className="body">
      <div className="ColumnTitle">
        <div className="Title">
          <div className="TitleText1">Permits Required:</div>
        </div>
      </div>
      <div className="Column">
        <div className="Row">
          <div className="TitleText1">Client Work Permit</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (
                Table.ClientWorkPermit !== null ||
                Table.ClientWorkPermit !== undefined
              ) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  ClientWorkPermit: !Table.ClientWorkPermit,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  ClientWorkPermit: true,
                });
              }
            }}
          >
            {Table.ClientWorkPermit ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>

        <div className="Row">
          <div className="TitleText1">Hot Work Permit</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (
                Table.HotWorkPermit !== null ||
                Table.HotWorkPermit !== undefined
              ) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  HotWorkPermit: !Table.HotWorkPermit,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  HotWorkPermit: true,
                });
              }
            }}
          >
            {Table.HotWorkPermit ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>

        <div className="Row">
          <div className="TitleText1">Air Monitoring</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (
                Table.AirMonitoring !== null ||
                Table.AirMonitoring !== undefined
              ) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  AirMonitoring: !Table.AirMonitoring,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  AirMonitoring: true,
                });
              }
            }}
          >
            {Table.AirMonitoring ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>

        <div className="Row">
          <div className="TitleText1">Ground Disturbance</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (
                Table.GroundDisturbance !== null ||
                Table.GroundDisturbance !== undefined
              ) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  GroundDisturbance: !Table.GroundDisturbance,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  GroundDisturbance: true,
                });
              }
            }}
          >
            {Table.GroundDisturbance ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>
      </div>

      <div className="Column">
        <div className="Row">
          <div className="TitleText1">Confined Space</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (
                Table.ConfinedSpace !== null ||
                Table.ConfinedSpace !== undefined
              ) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  ConfinedSpace: !Table.ConfinedSpace,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  ConfinedSpace: true,
                });
              }
            }}
          >
            {Table.ConfinedSpace ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>

        <div className="Row">
          <div className="TitleText1">Excavation</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (Table.Excavation !== null || Table.Excavation !== undefined) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Excavation: !Table.Excavation,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Excavation: true,
                });
              }
            }}
          >
            {Table.Excavation ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"></div>
            )}
          </button>
        </div>

        <div className="Row">
          <div className="TitleText1">Other</div>
        </div>
        <div className="RowBool">
          <button
            className="SubBtnJSA"
            title="Lock"
            onClick={() => {
              if (Table.Other !== null || Table.Other !== undefined) {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Other: !Table.Other,
                });
              } else {
                //[temp] = [event];
                setTable({
                  ...Table,
                  Other: true,
                });
              }
            }}
          >
            {Table.Other ? (
              <div className="true">
                <div className="trueText">
                  <ImCheckmark className="checkmark" />
                </div>
              </div>
            ) : (
              <div className="false"> </div>
            )}
          </button>
        </div>
        <div className="Row"></div>
        <div className="RowBool"></div>
      </div>
    </div>
  );
}
