import React, { useState, useEffect } from "react";
import "../../CSS/FRHeader.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function FRHeader(props) {
  const [Line0, setLine0] = useState({});
  const [Line1, setLine1] = useState({});
  const [Line2, setLine2] = useState({});
  useEffect(() => {
    if (Object.keys(Line0).length !== 0) {
      props.setHeader(props.Header, (props.Header[0] = { Line0 }));
    }
    if (Object.keys(Line1).length !== 0) {
      props.setHeader(props.Header, (props.Header[1] = { Line1 }));
    }
    if (Object.keys(Line2).length !== 0) {
      props.setHeader(props.Header, (props.Header[2] = { Line2 }));
    }
    if (props.Header !== undefined) {
      if (props.Header[0] !== undefined) {
        setLine0(props.Header[0].Line0);
        if (props.Header[0].Line0.Date !== undefined) {
          const tempDate = new Date(props.Header[0].Line0.Date);
          props.setStartDate(tempDate);
        }
      }
      if (props.Header[1] !== undefined) {
        setLine1(props.Header[1].Line1);
      }
      if (props.Header[2] !== undefined) {
        setLine2(props.Header[2].Line2);
      }
    }
  }, [props, Line0, Line1, Line2]);
  return (
    <div className="body">
      <div className="Column2">
        <div className="Row">
          <div className="TextTitle">Foreman:</div>
        </div>
        <div className="Row">
          <input
            className="TextInput"
            placeholder=""
            value={Line0.Foreman}
            onChange={(event) => {
              setLine0({ ...Line0, Foreman: event.target.value });
            }}
          />
        </div>
        <div className="Row">
          <div className="TextTitle">Date:</div>
        </div>
        <div className="Row">
          <DatePicker
            selected={props.startDate}
            onChange={(event) => {
              props.setStartDate(event);
              setLine0({ ...Line0, Date: event.toString() });
            }}
          />
        </div>
        <div className="Row">
          <div className="TextTitle">Day of Week:</div>
        </div>
        <div className="Row">
          <input
            className="TextInput"
            placeholder=""
            value={Line0.DayOfWeek}
            onChange={(event) => {
              setLine0({ ...Line0, DayOfWeek: event.target.value });
            }}
          />
        </div>
      </div>

      <div className="Column2">
        <div className="Row">
          <div className="TextTitle">Project ID:</div>
        </div>
        <div className="Row">
          <input
            className="TextInput"
            placeholder=""
            value={Line1.ProjectID}
            onChange={(event) => {
              setLine1({ ...Line1, ProjectID: event.target.value });
            }}
          />
        </div>
        <div className="Row">
          <div className="TextTitle">Project Name:</div>
        </div>
        <div className="Row">
          <input
            className="TextInput"
            placeholder=""
            value={Line1.ProjectName}
            onChange={(event) => {
              setLine1({ ...Line1, ProjectName: event.target.value });
            }}
          />
        </div>
        <div className="Row">
          <div className="TextTitle">Weather:</div>
        </div>
        <div className="Row">
          <input
            className="TextInput"
            placeholder=""
            value={Line1.Weather}
            onChange={(event) => {
              setLine1({ ...Line1, Weather: event.target.value });
            }}
          />
        </div>
      </div>

      <div className="Column2">
        <div className="Row3">
          <div className="TextTitle">
            Did your crew rain/snow out today (Yes or No)
          </div>
        </div>
        <div className="Row">
          <input
            className="TextInput"
            placeholder=""
            value={Line2.RainOut}
            onChange={(event) => {
              setLine2({ ...Line2, RainOut: event.target.value });
            }}
          />
        </div>
        <div className="Row3">
          <div className="TextTitle">Extra Work (Yes or No)</div>
        </div>
        <div className="Row">
          <input
            className="TextInput"
            placeholder=""
            value={Line2.Extra}
            onChange={(event) => {
              setLine2({ ...Line2, Extra: event.target.value });
            }}
          />
        </div>
      </div>
    </div>
  );
}
